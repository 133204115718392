import React, { Fragment, useEffect, useRef, useState } from "react";
import AdminLayout from "../../component/layout/AdminLayout/AdminLayout";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  CorporateLoader,
  GetAllAppointmentlist,
  GetAlllistData,
  UpdateAllStatus,
  UpdateAppointmentStatus,
} from "../../Redux/Action/CorporateAction";
import { MdClose } from "react-icons/md";
import { Menu, Transition } from "@headlessui/react";
import LimitArrow from "../../images/LimitArrow.svg";
import calender from "../../images/Doctor/calender.png";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseOutsideClick from "../../component/Admin/UseOutsideClick";
import Loader from "../../component/Admin/Loader";

function Panel() {
  const customStyle = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Roboto",
        color: "#1A1A1A",
      };
    },
    option: (defaultStyles) => ({
      ...defaultStyles,
      fontFamily: "Roboto",
      color: "#1A1A1A",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#808080",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#808080",
    }),
    control: (base, state) => ({
      ...base,
      height: "40px",
      width : '130px',
      borderColor: state.isFocused ? "#FCDD00" : "#808080",
      "&:hover": {
        borderColor: "#FCDD00",
      },
      boxShadow: "none !important",
    }),
    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: state.isSelected ? "#1A1A1A" : state.data.color,
      backgroundColor: state.isSelected ? "#FCDD00" : "white",
      ":hover": {
        backgroundColor: "#FFFCE1",
      },
    }),
    menuList: (base) => ({
      ...base,
      width : '100%',
      maxHeight: "200px",
      overflow: "Y",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#FCDD00",
      },
    }),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [ViewModal, setViewModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRangeText, setDateRangeText] = useState(new Date());
  const [openCalender, setopenCalender] = useState(false);

  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (openCalender) {
      setopenCalender(false);
    }
  });

  const viewref = useRef();

  UseOutsideClick(viewref, () => {
    if (ViewModal) {
      setViewModal(false);
    }
  });

  useEffect(() => {
    setSearchParams(searchParams);
    const sds = location.search.slice(1).split("&");
    var start = new Date();
    var end = new Date();
    let unix_timestamp = new Date(start.setUTCHours(0,0,0,0))?.getTime();
    let unix_timestamp1 = new Date(end.setUTCHours(23,59,59,999))?.getTime();
    
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/panel",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            status: StatusOptions[0]?.value,
            startDate: unix_timestamp,
            endDate: unix_timestamp1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/panel",
        search: `?status=${StatusOptions[0]?.value}&startDate=${unix_timestamp}&endDate=${unix_timestamp1}`,
      });
    }
    const param = searchParams.get("search");
    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
      delete object.search;
    }
    const limitparam = searchParams.get("limit");
    const pageparam = searchParams.get("page");
    if (limitparam) {
      searchParams.delete("limit");
      setSearchParams(searchParams);
      delete object.limit;
    }
    if (pageparam) {
      searchParams.delete("page");
      setSearchParams(searchParams);
      delete object.page;
    }
    dispatch(
      GetAllAppointmentlist({
        ...object,
        status: StatusOptions[0]?.value,
        startDate: unix_timestamp,
        endDate: unix_timestamp1,
      })
    );
  }, []);

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if (newlimit?.length > 0) {
      setpage(newlimit);
    }
  }, [searchParams]);

  useEffect(() => {
    const newpage = searchParams.get("page");
    if (newpage?.length > 0 && newpage !== null) {
      setselectpage(newpage - 1);
    }
  }, []);

  const itemsPerPageOptions = [
    { value: 10, label: 10, id: 1 },
    { value: 25, label: 25, id: 2 },
    { value: 50, label: 50, id: 3 },
  ];
  const StatusOptions = [
    { value: "Pending", label: "Pending", },
    { value: "Approved", label: "Approved", },
    { value: "Rejected", label: "Rejected", },
    { value: "All", label: "All", },
  ];

  const [page, setpage] = useState(itemsPerPageOptions[0]?.value);
  const [Search, setSearch] = useState("");
  const [selectpage, setselectpage] = useState("");
  const [Status, setStatus] = useState(StatusOptions[0]?.value);
  const [userDetail, setuserDetail] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  
  // useEffect(() => {
  //   setpage(itemsPerPageOptions[0]?.value);
  // }, []);

  useEffect(() => {
    const newlimit = searchParams.get("status");
    if (newlimit?.length > 0) {
      setStatus(newlimit);
    }
  }, [searchParams]);

  const newdata = useSelector(
    (state) => state?.corporateData?.GetAppointmentlist.data
  );

  function formatDate(date) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }
  const handleSelection = (range) => {
    setSelectionRange(range.selection);
    const startDateText = formatDate(range.selection.startDate);
    const endDateText = formatDate(range.selection.endDate);

      setDateRangeText(`${startDateText} - ${endDateText}`);
      if(startDateText === endDateText){
        setopenCalender(true)
      }else{
        setopenCalender(false)
      }
      
      let unix_timestamp =  new Date(new Date(startDateText).setUTCHours(23,59,59,999))?.getTime();
      let unix_timestamp1 = Math?.floor(
        new Date(endDateText)?.getTime() + 86400000
      );
      const sds = location.search.slice(1).split("&");

      let object = {};
      if (location.search.includes("?")) {
        sds.map((item) => {
          let newItem = item.split("=");
          object[newItem[0]] = newItem[1];
        });
        history({
          pathname: "/admin/admin_user/panel",
          search:
            "?" +
            new URLSearchParams({
              ...object,
              startDate: unix_timestamp,
              endDate: unix_timestamp1,
            }).toString(),
        });
      } else {
        history({
          pathname: "/admin/admin_user/panel",
          search: `?endDate=${unix_timestamp1}`,
        });
      }
      dispatch(
        GetAllAppointmentlist({
          ...object,
          startDate: unix_timestamp,
          endDate: unix_timestamp1,
        })
      );
    dispatch(CorporateLoader());
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };
  

  const [Itemperpage, setItemperpage] = useState("");

  const pageCount = Math.ceil(Number(newdata?.count) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [newdata]);

  // for select page data
  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      setselectpage(0);
      history({
        pathname: "/admin/admin_user/panel",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value , page : 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/panel",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(GetAllAppointmentlist({ ...object, limit: item.value , page : 1}));
    dispatch(CorporateLoader());
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };

  const handleFirstPage = () => {
    setselectpage(0);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/panel",
        search: "?" + new URLSearchParams({ ...object, page: 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/panel",
        search: `?page=1`,
      });
    }
    dispatch(GetAllAppointmentlist({ ...object, page: 1 }));
    dispatch(CorporateLoader());
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };
  const handleLastPage = () => {
    const lastPage = pageCount - 1;
    setselectpage(lastPage);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/panel",
        search:
          "?" +
          new URLSearchParams({ ...object, page: lastPage + 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/panel",
        search: `?page=${lastPage + 1}`,
      });
    }
    dispatch(GetAllAppointmentlist({ ...object, page: lastPage + 1 }));
    dispatch(CorporateLoader());
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };
  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/admin/admin_user/panel",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/panel",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(GetAllAppointmentlist({ ...object, page: selected.selected + 1 }));
    dispatch(CorporateLoader());
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };

  const handleStatus = (value) => {
    setStatus(value?.value);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/panel",
        search:
          "?" +
          new URLSearchParams({ ...object, status: value?.value }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/panel",
        search: `?status=${value?.value}`,
      });
    }
    dispatch(GetAllAppointmentlist({ ...object, status: value?.value }));
    dispatch(CorporateLoader());
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };

  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/panel",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/panel",
        search: `?search=${Search}`,
      });
    }
    dispatch(GetAllAppointmentlist({ ...object, search: Search }));
    dispatch(CorporateLoader());
  };

  const removeQueryParams = () => {
    const param = searchParams.get("search");
    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
      const sds = location.search.slice(1).split("&");
      let object = {};
      if (location.search.includes("?")) {
        sds.map((item) => {
          let newItem = item.split("=");
          object[newItem[0]] = newItem[1];
        });
      }
      delete object.search;
      dispatch(GetAllAppointmentlist({ ...object }));
      dispatch(CorporateLoader());
    }
  };

  const [appStatus, setappStatus] = useState("");
  const onSubmitAppointmentForm = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(
      UpdateAppointmentStatus(userDetail?._id, appStatus, { ...object })
    );
    dispatch(CorporateLoader());
    setViewModal(false);
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };

  function formattedStartDate(d) {
    let month = String(d?.getMonth() + 1);
    let day = String(d?.getDate());
    const year = String(d?.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}/${month}/${year}`;
  }

  function formattedTableDate(timestamp) {
    const date = new Date(timestamp * 1000); 

    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear().toString();

    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  function formattedCreatedDate(timestamp) {
    const date = new Date(timestamp); 

    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear().toString();

    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const loader = useSelector((state) => state?.corporateData?.corporateloader);

  useEffect(() => {
    dispatch(CorporateLoader());
  },[])


  const Exportlistdata = () => {
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(GetAlllistData({ ...object }))
    dispatch(CorporateLoader());
  }


  // checkbox functionality for table

  const [CheckAll, setCheckAll] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [Actionmodal, setActionmodal] = useState(false);

  const onSubmitRequestForm = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(
      UpdateAllStatus(CheckAll , appStatus , { ...object })
    );
    dispatch(CorporateLoader());
    setActionmodal(false);
    setIsCheckAll(false)
    setIsCheck(false)
    setCheckAll([])
  };
  
  const handleChange = (e) => {
    if(e.target.checked && newdata){
        const temp = []
        newdata?.healthData?.map((m)=> temp.push(m._id))
        setCheckAll(temp)
        setIsCheckAll(true)
        setIsCheck(true)
    }else{
        setCheckAll([])
        setIsCheckAll(false)
        setIsCheck(false)
    }      
  }

  useEffect(() => {
    if((CheckAll.length > 0) && (CheckAll.length === newdata?.healthData.length)){
        setIsCheckAll(true)
    }else{
        setIsCheckAll(false)
    }
  }, [CheckAll])
  

    // for list

   const selectsingleitem = (checked,id) => {
       let temp = [...CheckAll] 
       if(checked){
            !CheckAll.includes(id) && temp.push(id) 
            setIsCheck(true)
       }else{
          temp = temp.filter(function(f){
            return f !== id;
            })
        }
         if(temp.length === 0){
            setIsCheck(false)
        }
       setCheckAll(temp)
   }

   const actionref = useRef();

   UseOutsideClick(actionref, () => {
     if (Actionmodal) {
       setActionmodal(false);
     }
   });
 
 
  //  const getupdatedata = useSelector((state) => state?.corporateData?.Getupdatedata);

  return (
    <div>
      <AdminLayout>
      {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-[100] bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}
        <div className="h-full px-5 py-[20px] xl:py-[30px]">
          <div className="bg-white px-4 pt-4 pb-14 lg:pb-4 rounded-lg  w-full mb-5 relative">
            <div className=" flex flex-wrap items-center justify-between">
              <div className=" flex items-center justify-between">
                <Select
                  className=" z-50"
                  options={StatusOptions}
                  placeholder="Select"
                  value={StatusOptions?.filter(
                    (option) => option.value === Status
                  )}
                  onChange={handleStatus}
                  isSearchable={false}
                  styles={customStyle}
                />
              {isCheck && Status === 'Pending' && <button onClick={() => setActionmodal(true)} className="bg-[#fae006]  px-4 rounded-[8px] ml-[5px] lg:ml-[10px]  py-2 font-medium">Action</button>}
              </div>
              <div className=" absolute left-[38%] xl:left-[40%] 2xl:left-[50%] transform top-[81%] lg:top-[50%] translate-x-[-50%] translate-y-[-50%]">
              <div className="relative mt-3 sm:mt-0">
                <div className="absolute top-[8px] left-[20px] text-gray-400 text-2xl">
                  <RiSearch2Line className="w-6 h-6" />
                </div>

                <form
                  onSubmit={(e) => {
                    e?.preventDefault();
                    if (Search.trim() !== "") {
                      submitsearch(e);
                    }
                  }}
                >
                  <input
                    className="h-full bg-[#FFFCE1] border rounded-[8px] placeholder:font-medium py-2 pl-[50px] w-[250px] placeholder:text-[#47505F]  placeholder:text-[14px]  outline-[#FCDD00]"
                    placeholder="Search By Name"
                    value={Search}
                    onChange={(e) => {
                      const inputValue = e.target.value.trimStart();
                      setSearch(inputValue);
                      if (inputValue.trim() === "") {
                        removeQueryParams();
                      }
                    }}
                  />
                </form>
              </div>
              </div>
              <div className=" flex items-center flex-wrap ">
              {newdata?.healthData?.length > 0 && <button onClick={() => Exportlistdata()}  className="bg-[#fae006]  px-4 rounded-[8px] mr-[5px] lg:mr-[10px] mt-[10px] lg:mt-0 py-2 font-medium">Export</button>}
                <div className=" relative mt-[10px] lg:mt-0">
                  <button
                    className=" bg-[#fae006] flex px-4 rounded-[8px] py-2 font-medium"
                    onClick={() => setopenCalender(!openCalender)}
                  >
                    <img src={calender} className="mr-[12px]" alt="calenderimg"></img>
                    {formattedStartDate(selectionRange?.startDate)} -{" "}
                    {formattedStartDate(selectionRange?.endDate)}
                  </button>
                  {openCalender && (
                    <div className=" absolute right-0 z-50" ref={ref}>
                      <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelection}
                        rangeColors={["#FCDD00"]}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        maxDate={new Date()}
                        popperProps={{
                          strategy: "fixed",
                        }}
                        direction="horizontal"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* --------------------------------------------Content Table------------------------------------------------------- */}
          <div className="w-full h-[520px] xl:h-[610px]   overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
            {newdata?.healthData?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <p className="font-Roboto text-xl ">No Data Available.</p>
              </div>
            ) : (
              <table className="corporate-table w-[100%] text-left   font-Poppins">
                <thead className="">
                  <tr className=" bg-[#fff] z-20">
                    {Status === 'Pending' && <th>
                      <div className="text-[12px] 2xl:text-[16px] pl-[25px] 2xl:pl-[40px] py-3 xl:py-5 min-w-[60px] flex items-center">
                      <input  type="checkbox" checked={isCheckAll}
        onChange={handleChange}  className="cursor-pointer relative w-[18px] h-[18px] border rounded border-DCDCDC bg-white dark:bg-gray-800 outline-none"  />
                      </div>
                    </th>}
                    <th>
                      <div className={`${Status !== 'Pending' && 'pl-[25px] 2xl:pl-[40px]'} text-[12px] 2xl:text-[16px] py-3 xl:py-5 min-w-[110px]`}>
                       Corporate Name
                      </div>
                    </th>
                    <th>
                      <div className="text-[12px] 2xl:text-[16px] min-w-[100px]">
                        Name
                      </div>
                    </th>
                    <th>
                      <div className="text-[12px] 2xl:text-[16px] min-w-[100px]">
                        Mobile No.
                      </div>
                    </th>
                    <th>
                      <div className="text-[12px] 2xl:text-[16px] min-w-[100px] 2xl:min-w-[130px]">
                      Created Date
                      </div>
                    </th>
                    <th>
                      <div className="text-[12px] 2xl:text-[16px] min-w-[100px] 2xl:min-w-[130px]">
                        Date 1
                      </div>
                    </th>
                    <th>
                      <div className="text-[12px] 2xl:text-[16px] min-w-[100px] 2xl:min-w-[130px]">
                        Date 2
                      </div>
                    </th>
                    {Status === 'All' && <th>
                      <div className="text-[12px] 2xl:text-[16px] min-w-[80px]">
                        Status
                      </div>
                    </th>}
                    <th>
                      <div className="text-[12px] 2xl:text-[16px] min-w-[120px]">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newdata?.healthData?.length > 0 &&
                    newdata?.healthData?.map((user, index) => (
                      <tr key={index}>
                        {/* for Corporate Name  */}
                       {Status === 'Pending' && <td className=" pl-[25px] 2xl:pl-[40px] py-2 xl:py-[11.5px] h-[46px]">
                        <input  onChange={(e) => {selectsingleitem(e.target.checked,user._id)}} type="checkbox" checked={CheckAll?.includes(user._id)} className="cursor-pointer relative w-[18px] h-[18px] border rounded border-DCDCDC bg-white dark:bg-gray-800 outline-none" />
                        </td>}

                        {/* for corporate name  */}
                        {user?.corporateName?.length > 25 ? (
                          <td className={`${Status !== 'Pending' && 'pl-[25px] 2xl:pl-[40px]'} py-2 xl:py-[11.5px]`}>
                            <div className="tooltip capitalize text-[12px] 2xl:text-[16px] pr-3">
                              {`${user?.corporateName?.substring(0, 25)}...`}
                              <span className="tooltiptext">{user.corporateName}</span>
                            </div>
                          </td>
                        ) : (
                          <td className={`${Status !== 'Pending' && 'pl-[25px] 2xl:pl-[40px]'} capitalize py-2 xl:py-[11.5px]`}>
                            <div className="text-[12px] 2xl:text-[16px] pr-3">{user.corporateName}</div>
                          </td>
                        )}

                        {/* for name  */}
                        {user?.name?.length > 25 ? (
                          <td className="py-2 xl:py-[11.5px]">
                            <div className="tooltip capitalize text-[12px] 2xl:text-[16px] pr-3">
                              {`${user?.name?.substring(0, 25)}...`}
                              <span className="tooltiptext">{user.name}</span>
                            </div>
                          </td>
                        ) : (
                          <td className="capitalize py-2 xl:py-[11.5px]">
                            <div className="text-[12px] 2xl:text-[16px] pr-3">{user.name}</div>
                          </td>
                        )}


                        {/* for phone no  */}
                        <td>
                          <p className="text-[12px] 2xl:text-[16px]">
                            {user.contact}
                          </p>
                        </td>

                        {/* for status  */}
                        <td>
                          <p className="text-[12px] 2xl:text-[16px]">
                            {formattedCreatedDate(user.created)}
                          </p>
                        </td>
                        <td>
                          <p className="text-[12px] 2xl:text-[16px]">
                            {formattedTableDate(user.date1)}
                          </p>
                        </td>
                        <td>
                          <p className="text-[12px] 2xl:text-[16px]">
                            {formattedTableDate(user.date2)}
                          </p>
                        </td>
                       {Status === 'All' && <td>
                          <p className={`text-[12px] 2xl:text-[16px] ${user.status === 'Rejected' && 'text-[#ff0000]'} ${user.status === 'Approved' && 'text-[#32CD32]'}`}>
                            {user.status}
                          </p>
                        </td>}

                        {/* for action  */}
                        <td>
                          <button
                            onClick={() => {
                              setViewModal(true);
                              setuserDetail(user);
                            }}
                            className={`px-[14px] py-[4px] ${
                              user.status === "Pending"
                                ? "bg-[#FAE006]"
                                : "border border-[#1D9BD7]"
                            }  text-[14px] leading-[16px] font-medium text-[#1A1A1A] rounded-[4px]`}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>

          {/* --------------------------------------------Footer---------------------------------------------------------- */}

          {newdata?.healthData?.length !== 0 && (
            <div className="footer-content mt-[20px]">
              <div className=" md:flex justify-between items-center sticky bottom-0">
                <div className="flex gap-2 items-center">
                  <p className="capitalize">View</p>
                  <div className="">
                    <Menu as="div" className="relative text-left">
                      <div>
                        <Menu.Button className="flex px-[10px] ml-[24px] min-h-[37px] bg-white border-2 border-[#F2F2F2] min-w-[60px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                          <p className="text-[#47505F] font-medium font-[poppins]">
                            {page}
                          </p>
                          <img src={LimitArrow} alt="LimitArrow" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[70%] rounded-[12px] bg-white shadoe-sm focus:outline-none border  mb-2">
                          {itemsPerPageOptions?.map((e) => (
                            <div key={e?.id}>
                              <Menu.Item>
                                {({ active }) => (
                                  <p
                                    className={classNames(
                                      active
                                        ? "bg-[#FFFCE1] text-[#1A1A1A]"
                                        : "",
                                      "block px-2 py-2 text-sm cursor-pointer"
                                    )}
                                    onClick={() => onselectedpage(e)}
                                  >
                                    {e?.value}
                                  </p>
                                )}
                              </Menu.Item>
                            </div>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                {/* --------------------------------------------Footer Pagination---------------------------------------------------------- */}

                <div className="flex items-center mt-3 md:mt-0">
                  <button
                    className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[5.5px]"
                    onClick={handleFirstPage}
                  >
                    &lt;&lt;
                  </button>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={Itemperpage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    forcePage={selectpage}
                  />
                  <button
                    className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[5.5px]"
                    onClick={handleLastPage}
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/*----- view modal -----*/}

        {ViewModal && (
          <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-[100] bg-opacity-50">
            <form onSubmit={onSubmitAppointmentForm}>
              <div
                ref={viewref}
                className="w-[340px] lg:min-w-[1114px]  bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]"
              >
                <div className="rounded-t-2xl border-b border-[#888888] px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
                  <div className="text-[18px] xl:text-xl">
                    Health Check-up Details
                  </div>
                  <button
                    onClick={() => {
                      setViewModal(false);
                    }}
                  >
                    <MdClose className="text-gray-500 text-2xl hover:text-black" />
                  </button>
                </div>
                <div className="px-3 xl:px-5 pt-[20px] grid grid-cols-12 gap-[12px] h-[350px] 2xl:h-auto overflow-auto 2xl:overflow-visible">
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                    Corporate Name
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Corporate Name"
                      disabled
                      value={userDetail?.corporateName}
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Employee Name
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      disabled
                      placeholder="Enter Employee Name"
                      value={userDetail?.name}
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Mobile No
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Mobile NO"
                      value={userDetail?.contact}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                    Patient or Member Name
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Patient or Member Name"
                      value={userDetail?.member_name}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-6 lg:col-span-2">
                    <span className="font-Poppins text-[13px] font-medium">
                      Gender
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Gender"
                      value={userDetail?.gender}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-6 lg:col-span-2">
                    <span className="font-Poppins text-[13px] font-medium">
                      Age
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Age"
                      value={userDetail?.age}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-2">
                    <span className="font-Poppins text-[13px] font-medium">
                      Relation
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Relation"
                      value={userDetail?.relation}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Types of Services
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Types of Services"
                      value={userDetail?.services}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Appointment Date 1
                      
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Appointment Date 1"
                      value={formattedTableDate(userDetail?.date1)}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Appointment Date 2
                      
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Appointment Date 2"
                      value={formattedTableDate(userDetail?.date2)}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                    Hospital or Diagnostics Center Name
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Hospital or Diagnostics Center Name"
                      value={userDetail?.hospital_name}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      City
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter City"
                      value={userDetail?.city}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Pin Code
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Pin Code"
                      value={userDetail?.pincode}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Available Plans
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Available Plans"
                      value={userDetail?.available_plans}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Employee Home Address
                      
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Employee Home Address"
                      value={userDetail?.address}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex flex-col gap-1 col-span-12 lg:col-span-3">
                    <span className="font-Poppins text-[13px] font-medium">
                      Plan Cost
                    </span>
                    <input
                      className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                      type="text"
                      placeholder="Enter Plan Cost"
                      value={userDetail?.plan_cost}
                      disabled
                      autoComplete="off"
                    />
                  </div>
                </div>
                {userDetail.status === "Pending" ? (
                  <div className=" py-[20px] flex items-center justify-center">
                    <button
                      type="submit"
                      onClick={() => setappStatus("Rejected")}
                      className=" text-[#1a1a1a] mr-[10px] font-medium px-[29px] py-[6px] text-[18px] rounded-[12px] border border-[#1a1a1a] hover:scale-105"
                    >
                      Reject
                    </button>
                    <button
                      type="submit"
                      onClick={() => setappStatus("Approved")}
                      className=" text-[#1a1a1a] font-medium px-[20px] py-[7px] text-[18px] rounded-[12px] bg-[#fae006] hover:scale-105"
                    >
                      Approve
                    </button>
                  </div>
                ) : (
                  <div className=" py-[20px] flex items-center justify-center">
                    <button
                      onClick={() => setViewModal(false)}
                      className=" text-[#1a1a1a] mr-[10px] font-medium px-[29px] py-[6px] text-[18px] rounded-[12px] border border-[#1a1a1a] hover:scale-105"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        )}

        {/* Actio modal */}

        {Actionmodal && (
          <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-[100] bg-opacity-50">
            <form onSubmit={onSubmitRequestForm}>
              <div
                ref={actionref}
                className="w-[360px] lg:min-w-[400px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]"
              >
                <div className="rounded-t-2xl border-b border-[#888888] px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
                  <div className="text-[18px] xl:text-xl">
                  Approve/Reject Request
                  </div>
                  <button
                    onClick={() => {
                      setActionmodal(false);
                    }}
                  >
                    <MdClose className="text-gray-500 text-2xl hover:text-black" />
                  </button>
                </div>
                <div>
                <p className="font-Poppins text-[16px] text-center font-medium pt-5 px-2">Are you sure you want to Approve or Reject this Request?</p>
                </div>
                  <div className=" py-[20px] flex items-center justify-center">
                    <button
                      type="submit"
                      onClick={() => setappStatus("Rejected")}
                      className=" text-[#1a1a1a] mr-[10px] font-medium px-[29px] py-[6px] text-[18px] rounded-[12px] border border-[#1a1a1a] hover:scale-105"
                    >
                      Reject
                    </button>
                    <button
                      type="submit"
                      onClick={() => setappStatus("Approved")}
                      className=" text-[#1a1a1a] font-medium px-[20px] py-[7px] text-[18px] rounded-[12px] bg-[#fae006] hover:scale-105"
                    >
                      Approve
                    </button>
                  </div>
              </div>
            </form>
          </div>
        )}
        <ToastContainer />
      </AdminLayout>
    </div>
  );
}

export default Panel;

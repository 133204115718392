export const actionTypes = {
  CORPORATE_LIST: "CORPORATE_LIST",
  CORPORATE_DETAIL: "CORPORATE_DETAIL",
  CORPORATE_OPTION_LIST: "CORPORATE_OPTION_LIST",
  ACTIVE_CORPORATE_OPTION_LIST: "ACTIVE_CORPORATE_OPTION_LIST",
  NEW_CORPORATE: "NEW_CORPORATE",
  LOADER: "LOADER",
  EDIT_CORPORATE_DETAIL: "EDIT_CORPORATE_DETAIL",
  CORPORATE_MAIL_STATUS: "CORPORATE_MAIL_STATUS",
  CORPORATE_LOGIN_DETAILS: "CORPORATE_LOGIN_DETAILS",
  GET_APPOINTMENT_LIST : 'GET_APPOINTMENT_LIST',
  GET_All_LIST_DATA : 'GET_All_LIST_DATA',
  GET_UPDATE_LIST_DATA : 'GET_UPDATE_LIST_DATA',
  UPDATE_MIRAJ_FORM : 'UPDATE_MIRAJ_FORM'
};

const INITIAL_STATE = {
  Corporatelist: [],
  CorporateDetail: [],
  CorporateOptionlist: [],
  ActiveCorporateOptionList: [],
  NewCorporate: [],
  corporateloader: false,
  editcorpdetail: [],
  CorpMailStatus: [],
  CorpoLoginDetails: [],
  GetAppointmentlist: [],
  Getlistdata : [],
  Getupdatedata : [],
  mirajdata : [],
};

const CorporateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOADER:
      return {
        ...state,
        corporateloader: action.corporateloader,
      };
    case actionTypes.CORPORATE_LIST:
      return {
        ...state,
        Corporatelist: action.Corporatelist,
      };
    case actionTypes.CORPORATE_DETAIL:
      return {
        ...state,
        CorporateDetail: action.CorporateDetail,
      };
    case actionTypes.CORPORATE_OPTION_LIST:
      return {
        ...state,
        CorporateOptionlist: action.CorporateOptionlist,
      };
    case actionTypes.UPDATE_MIRAJ_FORM:
      return {
        ...state,
        mirajdata: action.mirajdata,
        corporateloader: action.corporateloader,
      };
    case actionTypes.ACTIVE_CORPORATE_OPTION_LIST:
      return {
        ...state,
        ActiveCorporateOptionList: action.ActiveCorporateOptionList,
      };
    case actionTypes.NEW_CORPORATE:
      return {
        ...state,
        NewCorporate: action.NewCorporate,
        corporateloader: action.corporateloader,
      };
    case actionTypes.EDIT_CORPORATE_DETAIL:
      return {
        ...state,
        editcorpdetail: action.editcorpdetail,
        corporateloader: action.corporateloader,
      };
    case actionTypes.CORPORATE_MAIL_STATUS:
      return {
        ...state,
        CorpMailStatus: action.CorpMailStatus,
        corporateloader: action.corporateloader,
      };
    case actionTypes.CORPORATE_LOGIN_DETAILS:
      return {
        ...state,
        CorpoLoginDetails: action.CorpoLoginDetails,
        corporateloader: action.corporateloader,
      };
    case actionTypes.GET_APPOINTMENT_LIST:
      return {
        ...state,
        GetAppointmentlist: action.GetAppointmentlist,
        corporateloader: action.corporateloader,
      };
    case actionTypes.GET_All_LIST_DATA:
      return {
        ...state,
        Getlistdata: action.Getlistdata,
        corporateloader: action.corporateloader,
      };
    case actionTypes.GET_UPDATE_LIST_DATA:
      return {
        ...state,
        Getupdatedata: action.Getupdatedata,
        corporateloader: action.corporateloader,
      };

    default:
      return state;
  }
};

export default CorporateReducer;

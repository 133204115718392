import React, { useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "../../images/DoctorChat.json";
import clickanimationData from "../../images/click.json";

const Chat = () => {
  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  const defaultoption = {
    loop: true,
    autoplay: true,
    animationData: clickanimationData,
  };

  useEffect(() => {
    const widgetId = "4c6d4b8f-91e2-44fb-b40f-c4e1ea5ccd79";

    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetId}`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-[#FFFCE1] w-full min-h-screen">
      <div className="flex flex-col justify-center items-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <p className="text-center font-Poppins font-bold text-[#1A1A1A] text-[17px] sm:text-[22px]">
          Click below chat button for start chatting with our customer support
          team!
        </p>
        <div className=" min-w-[350px] max-w-[550px] my-10">
          <Lottie options={defaultOption} />
        </div>
      </div>
      <div className="absolute bottom-[50px] right-[-27px] rotate-90">
        <div class="animation-container">
          <div class="flex">
            <p class="bg-[#FCDD00] p-2 rounded-l-[8px] font-Poppins text-[#1a1a1a]">
              Click Here
            </p>
            <p class="border-[20px] border-l-[#FCDD00] border-[#FFFCE1]"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

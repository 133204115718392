import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const actionTypes = {
  CORPORATE_LIST: "CORPORATE_LIST",
  CORPORATE_DETAIL: "CORPORATE_DETAIL",
  CORPORATE_OPTION_LIST: "CORPORATE_OPTION_LIST",
  ACTIVE_CORPORATE_OPTION_LIST: "ACTIVE_CORPORATE_OPTION_LIST",
  NEW_CORPORATE: "NEW_CORPORATE",
  LOADER: "LOADER",
  EDIT_CORPORATE_DETAIL: "EDIT_CORPORATE_DETAIL",
  CORPORATE_MAIL_STATUS: "CORPORATE_MAIL_STATUS",
  CORPORATE_LOGIN_DETAILS: "CORPORATE_LOGIN_DETAILS",
  GET_APPOINTMENT_LIST: "GET_APPOINTMENT_LIST",
  GET_All_LIST_DATA: "GET_All_LIST_DATA",
  GET_UPDATE_LIST_DATA: "GET_UPDATE_LIST_DATA",
  UPDATE_MIRAJ_FORM : 'UPDATE_MIRAJ_FORM'
};

export const CorporateLoader = () => {
  return {
    type: actionTypes.LOADER,
    corporateloader: true,
  };
};

// Corporate list

export const GetCorporatelist = (query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/corporate`, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.CORPORATE_LIST,
          Corporatelist: response.data,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// Corporate Detail

export const GetCorporateDetail = (_id) => {
  return async (dispatch) => {
    const authToken = localStorage.getItem("Admin");
    const token = JSON.parse(authToken)?.data?.token;
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/corporate/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.CORPORATE_DETAIL,
          CorporateDetail: response.data,
        });
      })
      .catch((err) => {
        // console.log(err)
      });
  };
};

// Corporate Option list

export const GetCorporateOptionlist = () => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/corporatename`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.CORPORATE_OPTION_LIST,
          CorporateOptionlist: response.data,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

//Active Corporate Option list

export const GetActiveCorporateOptionlist = () => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/corporateactive`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.ACTIVE_CORPORATE_OPTION_LIST,
          ActiveCorporateOptionList: response.data,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

//  create new coporate

export const CreateCorporate = (Data, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/admin/corporate`, Data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.NEW_CORPORATE,
          NewCorporate: response.data,
          corporateloader: false,
        });
        dispatch(GetCorporatelist());
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: actionTypes.LOADER,
            corporateloader: false,
          });
          toast.error(err?.response?.data?.error, {
            theme: "colored",
            autoClose: 5000,
          });
        }
      });
  };
};

// delete corporate

export const DeleteCorporate = (id, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/admin/corporate/${id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetCorporatelist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// update corporate

export const UpdateCorporate = (_id, status, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
  };

  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/corporate/${_id}`,
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch(GetCorporatelist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// for edit corporate detail
export const EditCorporate = (
  _id,
  corporateName,
  name,
  email,
  contactno,
  startDate,
  endDate,
  primary,
  secondary,
  file,
  selectpage,
  hContact,
  aContact
) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  var data = new FormData();
  data.append("corporateName", corporateName);
  data.append("name", name);
  data.append("email", email);
  data.append("contactno", contactno);
  data.append("startdate", startDate);
  data.append("enddate", endDate);
  data.append("primary", primary);
  data.append("secondary", secondary);
  data.append("logo", file);
  data.append("homContact", hContact);
  data.append("aloContact", aContact);

  return async (dispatch) => {
    await axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/corporate/profile/${_id}`,
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        const query = { page: selectpage + 1 };
        dispatch(GetCorporatelist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
        dispatch({
          type: actionTypes.EDIT_CORPORATE_DETAIL,
          editcorpdetail: response.data,
          corporateloader: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOADER,
          corporateloader: false,
        });
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

//for corporate mail
export const CorpMailStatus = (id) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/mailstatus/${id}`, null, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.CORPORATE_MAIL_STATUS,
          CorpMailStatus: response.data,
          corporateloader: false,
        });
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: actionTypes.LOADER,
            corporateloader: false,
          });
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

//for corporate login details
export const CorpLoginDetails = (id) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/mailpassword/${id}`, null, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.CORPORATE_LOGIN_DETAILS,
          CorpoLoginDetails: response.data,
          corporateloader: false,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: actionTypes.LOADER,
            corporateloader: false,
          });
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

// APPOINTMENT LIST

export const GetAllAppointmentlist = (query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/health/checkup`, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.GET_APPOINTMENT_LIST,
          GetAppointmentlist: response.data,
          corporateloader: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOADER,
          corporateloader: false,
        });
        // console.log(err);
      });
  };
};

// Update Appointment Status

export const UpdateAppointmentStatus = (id, status, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const data = {
    status: status,
  };
  return async (dispatch) => {
    await axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/health/status/${id}`,
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
        dispatch(GetAllAppointmentlist(query));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

// Export data

export const GetAlllistData = (query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/healthget/download`, {
        params: { ...query },
        responseType: "blob",
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.GET_All_LIST_DATA,
          Getlistdata: response.data,
          corporateloader: false,
        });
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();

        // link.remove();
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOADER,
          corporateloader: false,
        });
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
        // console.log(err);
      });
  };
};

export const UpdateAllStatus = (id, status, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const data = {
    selectedField: id,
    status: status,
  };
  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/status/multiple`, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.GET_UPDATE_LIST_DATA,
          Getupdatedata: response.data,
          corporateloader: false,
        });
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
        dispatch(GetAllAppointmentlist({ ...query }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

export const UpdateMirajform = (name, email,contact) => {
  const data = {
    name: name,
    email: email,
    contact:contact,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/miraj/form`,
        data,
     
      )
      .then((response) => {
        dispatch({
          type: actionTypes.UPDATE_MIRAJ_FORM,
          mirajdata: response.data,
          corporateloader: false,
        });
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_MIRAJ_FORM,
          corporateloader: false,
        });
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

import axios from "axios"

const actionTypes = {
    REPORT_DATA: "REPORT_DATA",
}

export const GetReportData = (data) => {
    const authToken = localStorage.getItem("Admin");
    const token = JSON.parse(authToken)?.data?.token;
    const id = JSON.parse(authToken)?.data?.corporateID
  
    return async (dispatch) => {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/totalreport?corporateID=${id}`, data, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          dispatch({
            type: actionTypes.REPORT_DATA,
            reportdata: response.data,
          });
        })
        .catch((err) => {
        //   console.log(err);
        });
    };
  };
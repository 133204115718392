import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const actionTypes = {
  EMPLOYEE_DETAIL: "EMPLOYEE_DETAIL",
  ADD_FAMILY_MEMBER: "ADD_FAMILY_MEMBER",
  RELATION_OPTION_LIST: "RELATION_OPTION_LIST",
  BLOOD_OPTION_LIST: "BLOOD_OPTION_LIST",
  FAMILY_MEMBER_LIST: "FAMILY_MEMBER_LIST",
  LOADER: "LOADER",
};

export const FamilyLoader = () => {
  return {
    type: actionTypes.LOADER,
    familyloader: true,
  };
};
// Employee Detail

export const GetEmpDetail = (_id) => {
  return async (dispatch) => {
    const authToken = localStorage.getItem("Admin");
    const token = JSON.parse(authToken)?.data?.token;
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/employee/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.EMPLOYEE_DETAIL,
          EmpDetail: response.data,
          
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// relation list
export const GetRelationlist = () => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/relation`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.RELATION_OPTION_LIST,
          RelationList: response.data,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// blood group list
export const GetBloodlist = () => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bloodgroup`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.BLOOD_OPTION_LIST,
          BloodList: response.data,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// hr add employee family member

export const AddFamilyMember = (Data) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/employee/family`, Data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_FAMILY_MEMBER,
          AddFamilyMember: response.data,
          familyloader: false,
        });

        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: actionTypes.LOADER,
            doctorloader: false,
          });
          toast.error(err?.response?.data?.error, {
            theme: "colored",
            autoClose: 5000,
          });
        }
      });
  };
};

// get family member list

export const GetFamilyMemberlist = (corporate, query) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/family?corporateID=${corporate}`, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.FAMILY_MEMBER_LIST,
          FamilyMemberList: response.data,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// family member status update 
export const UpdateFamily = (id, status,corporateID,employeeID, query,corporate,Search) => {
  console.log(query)
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
    corporateID: corporateID,
    employeeID: employeeID,
  };
  return async (dispatch) => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/memberupdate/${id}`, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetFamilyMemberlist(query,corporate,Search));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};


// delete Employee

export const DeleteFamily = (id, query,corporate) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/memberdelete/${id}`, {
        headers: {
          "X-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetFamilyMemberlist(corporate,query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>{
        if(err){
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      });
  };
};
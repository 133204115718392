import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CorporateLoader, UpdateMirajform } from "../Redux/Action/CorporateAction";
import { ToastContainer } from "react-toastify";
// import { MdClose } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../component/Admin/Loader";

function Mirajpage() {
  const search = window.location.search;
  const param = new URLSearchParams(search);

  const Mobile = param.get("contact");
  const token = param.get("token");

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [SuccessModal, setSuccessModal] = useState(false);

  const mirajdata = useSelector(state => state.corporateData.mirajdata)
  const loader = useSelector((state) => state?.corporateData?.corporateloader);

  console.log(mirajdata)
  useEffect(() => {
   if(mirajdata && mirajdata?.code === 200){
    setSuccessModal(true)
   }
  },[mirajdata])
  const dispatch = useDispatch();
  const onSubmitfrom = (e) => {
    e?.preventDefault();
    dispatch(UpdateMirajform(Name, Email,Mobile));
    dispatch(CorporateLoader());
    // setSuccessModal(true);
  };
  return (
    <div className=" relative">
        {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}
      <div className="w-[340px] px-3 xl:px-5 py-[20px] bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
        <form onSubmit={onSubmitfrom}>
          <div className=" grid grid-cols-12 gap-[12px]">
            <div className="flex flex-col gap-1 col-span-12 ">
              <span className="font-Poppins text-[13px] font-medium">Name</span>
              <input
                className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                type="text"
                placeholder="Enter Name"
                value={Name || ""}
                required
                onChange={(e) => setName(e.target.value?.trimStart())}
                autoComplete="off"
              />
            </div>
            <div className="flex flex-col gap-1 col-span-12 ">
              <span className="font-Poppins text-[13px] font-medium">
                Email id
              </span>
              <input
                className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                type="email"
                placeholder="Enter Email id"
                value={Email || ""}
                required
                onChange={(e) => setEmail(e.target.value?.trimStart())}
                autoComplete="off"
              />
            </div>
            <div className="flex flex-col gap-1 col-span-12 ">
              <span className="font-Poppins text-[13px] font-medium">
                Mobile No
              </span>
              <input
                className="py-[10px] xl:py-[13px] px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                type="number"
                disabled
                placeholder="Enter Mobile No"
                value={Mobile || ""}
                autoComplete="off"
              />
            </div>
          </div>
          <div className=" flex justify-center">
            <button
              type="submit"
              className=" text-[#1a1a1a] mt-5 font-medium px-[30px] py-[5px] text-[18px] rounded-[12px] bg-[#fae006] hover:scale-105"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      {SuccessModal && (
        <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
          <div className="bg-white min-w-[340px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
            {/* <div className=" px-3 xl:px-5 py-2 xl:py-3   flex justify-end items-center">
              <button
                onClick={() => {
                  setSuccessModal(false);
                }}
              >
                <MdClose className="text-gray-500 text-2xl hover:text-black" />
              </button>
            </div>
            <hr></hr> */}
            <div className="px-3 xl:px-5 py-3 xl:py-9 text-center">
            <p className=" font-semibold text-[20px] pb-2">Welcome Cure registration successfully completed!</p>
            <p className=" font-medium">Check your mail and download our app.</p>
            </div>
            <div className=" flex justify-center pb-5">
            <a href="https://www.welcomecure.com/"
              type="submit"
              className=" text-[#1a1a1a] font-medium px-[30px] py-[5px] text-[18px] rounded-[12px] bg-[#fae006] hover:scale-105"
            >
              Ok
            </a>
          </div>
          </div>
        </div>
      )}
      <ToastContainer/>
    </div>
  );
}

export default Mirajpage;

export const actionTypes = {
    ADD_LAB: "ADD_LAB",
    LAB_LIST: "LAB_LIST",
    LOADER: "LOADER",
    LAB_DETAIL: "LAB_DETAIL",
    EDIT_LAB_DETAIL:"EDIT_LAB_DETAIL",
}

const initialState = {
    addlab: [],
    lablist: [],
    labdetail: [],
    editlabdetail:[],
    labloader: false,
}

const addlabReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADER:
          return {
            ...state,
            labloader: action.labloader,
          };
        case actionTypes.ADD_LAB:
          return {
            ...state,
            addlab: action.addlab,
            labloader: action.labloader,
          };
          case actionTypes.LAB_LIST:
            return {
              ...state,
              lablist: action.lablist,
            };
            case actionTypes.LAB_DETAIL:
              return {
                ...state,
                labdetail: action.labdetail,
              };
              case actionTypes.EDIT_LAB_DETAIL:
              return {
                ...state,
                editlabdetail: action.editlabdetail,
              };
        default:
          return state;
      }
}

export default addlabReducer;
export const actionTypes = {
  EMPLOYEE_DETAIL: "EMPLOYEE_DETAIL",
  ADD_FAMILY_MEMBER: "ADD_FAMILY_MEMBER",
  RELATION_OPTION_LIST: "RELATION_OPTION_LIST",
  BLOOD_OPTION_LIST: "BLOOD_OPTION_LIST",
  FAMILY_MEMBER_LIST: "FAMILY_MEMBER_LIST",
  LOADER: "LOADER",
};

const INITIAL_STATE = {
  AddFamilyMember: [],
  FamilyMemberList: [],
  EmpDetail: [],
  RelationList: [],
  BloodList: [],
  familyloader: false,
};

const AddFamilyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOADER:
      return {
        ...state,
        familyloader: action.familyloader,
      };
    case actionTypes.EMPLOYEE_DETAIL:
      return {
        ...state,
        EmpDetail: action.EmpDetail,
      };
    case actionTypes.RELATION_OPTION_LIST:
      return {
        ...state,
        RelationList: action.RelationList,
      };
    case actionTypes.BLOOD_OPTION_LIST:
      return {
        ...state,
        BloodList: action.BloodList,
      };
    case actionTypes.ADD_FAMILY_MEMBER:
      return {
        ...state,
        AddFamilyMember: action.AddFamilyMember,
        familyloader: action.familyloader,
      };
    case actionTypes.FAMILY_MEMBER_LIST:
      return {
        ...state,
        FamilyMemberList: action.FamilyMemberList,
      };
    default:
      return state;
  }
};

export default AddFamilyReducer;

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import AdminLayout from "../../component/layout/AdminLayout/AdminLayout";
//imort action file
import {
  GetEmployeelist,
  DeleteEmployee,
  UpdateEmployee,
  EmpMailStatus,
  EmployeeLoader,
} from "../../Redux/Action/EmployeeAction";
import { GetCorporateOptionlist } from "../../Redux/Action/CorporateAction";
//import sweetAlert
import Swal from "sweetalert2";
//import icone
import { AiOutlineDelete, AiOutlineMail } from "react-icons/ai";
import { RiSearch2Line } from "react-icons/ri";
import ClearFilter from "../../images/clear filter icon.png";
import LimitArrow from "../../images/LimitArrow.svg";
import Loader from "../../component/Admin/Loader";
import { BiEdit } from "react-icons/bi";
import { EditEmployeeModal } from "../../component/Modal/AdminModal/EditEmployeeModal";


const Employee = () => {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [selectpage, setselectpage] = useState("");
  const itemsPerPageOptions = [
    { value: 10, label: 10, id: 1 },
    { value: 25, label: 25, id: 2 },
    { value: 50, label: 50, id: 3 },
  ];

  const Employeelist = useSelector(
    (state) => state?.Employelists?.EmployeList?.data
  );

  useEffect(() => {
    searchParams.delete("search");
    searchParams.delete("corporateID");
    setSearchParams(searchParams);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    delete object.corporateID;
    dispatch(GetEmployeelist({ ...object }));
    dispatch(GetCorporateOptionlist());
  }, []);

  const [checkvalue, setcheckvalue] = useState("");
  const [Itemperpage, setItemperpage] = useState("");
  const [page, setpage] = useState("");
  const pageCount = Math.ceil(Number(Employeelist?.count) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [Employeelist]);

  useEffect(() => {
    setpage(itemsPerPageOptions[0]?.value);
  }, []);

  // for select page data
  const onselectedpage = (item) => {
    setpage(item.value);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      setpage(item.value);
      // delete object.page;
      setselectpage(0)
      history({
        pathname: "/admin/admin_user/employee",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value,page: 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/employee",
        search: `?limit=${item.value}`,
      });
    }
    console.log(object,"shiviobject");
    dispatch(GetEmployeelist({ ...object, limit: item.value, search: Search,page: 1 }));
  };

  // for pagination
  const handleFirstPage = () => {
    setselectpage(0);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/employee",
        search: "?" + new URLSearchParams({ ...object, page: 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/employee",
        search: `?page=1`,
      });
    }
    dispatch(GetEmployeelist({ ...object, page: 1 }));
  };
  const handleLastPage = () => {
    const lastPage = pageCount - 1;
    setselectpage(lastPage);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/employee",
        search:
          "?" +
          new URLSearchParams({ ...object, page: lastPage + 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/employee",
        search: `?page=${lastPage + 1}`,
      });
    }
    dispatch(GetEmployeelist({ ...object, page: lastPage + 1 }));
  };
  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      
      history({
        pathname: "/admin/admin_user/employee",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/employee",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(GetEmployeelist({ ...object, page: selected.selected + 1, search: Search }));
  };

  //for search filter
  const [Search, setSearch] = useState("");
  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      delete object.page;
      history({
        pathname: "/admin/admin_user/employee",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/employee",
        search: `?search=${Search}`,
      });
    }
    dispatch(GetEmployeelist({ ...object, search: Search }));
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("search");
    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    delete object.page;
    history({
      pathname: "/admin/admin_user/employee",
      search:
        "?" +
        new URLSearchParams({
          ...object,
        }).toString(),
    });
    dispatch(GetEmployeelist({ ...object }));
    setselectpage((object.page = 0));
  };

  // for searchable-dropdwon
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [error, setError] = useState("");

  function handleSelect(selectedOption) {
    setSelectedCorporate(selectedOption?.value);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/employee",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            corporateID: selectedOption?.value,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/employee",
        search: `?corporateID=${selectedOption?.value}`,
      });
    }
    dispatch(
      GetEmployeelist({ ...object, corporateID: selectedOption?.value })
    );
  }

  //for mail 
  const handelMail = (id) => {
    dispatch(EmpMailStatus(id));
    dispatch(EmployeeLoader());
  }

  //for delete data in table
  const handleDelete = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Employee!",
      icon: "warning",
      iconColor: "#FCDD00",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#FCDD00",
      cancelButtonColor: "#FFFCE1",
      customClass: {
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sds = location.search.slice(1).split("&");
        let object = {};
        if (location.search.includes("?")) {
          sds.map((item) => {
            let newItem = item.split("=");
            object[newItem[0]] = newItem[1];
          });
        }
        if (
          Employeelist?.employeeData.length === 1 &&
          object.page != undefined &&
          object.page != 1
        ) {
          const currentPage = parseInt(object.page || 1, 10);
          history({
            pathname: "/admin/admin_user/employee",
            search: `?page=${currentPage > 1 ? currentPage - 1 : 1}`,
          });
          object.page = object.page - 1;
        }
        dispatch(DeleteEmployee(index, { ...object }));
      }
    });
  };

  const Corporatename = useSelector(
    (state) => state?.corporateData?.CorporateOptionlist?.data
  );

  useEffect(() => {
    const con =
      Corporatename &&
      Corporatename?.map((c) => ({
        value: c.corporateID,
        label: c.corporateName,
        color: c.status ? "black" : "#808080",
      }));
    setOptionList(con);
  }, [Corporatename]);

  // for searchable-dropdwon css
  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Roboto",
        color: "#1A1A1A",
      };
    },
    option: (defaultStyles) => ({
      ...defaultStyles,
      fontFamily: "Roboto",
      color: "#1A1A1A",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#808080",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#808080",
    }),
    control: (base, state) => ({
      ...base,
      height: "40px",
      borderColor: state.isFocused ? "#FCDD00" : "#808080",
      "&:hover": {
        borderColor: "#FCDD00",
      },
      boxShadow: "none !important",
    }),
    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: state.isSelected ? "#1A1A1A" : state.data.color,
      backgroundColor: state.isSelected ? "#FCDD00" : "white",
      ":hover": {
        backgroundColor: "#FFFCE1",
      },
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px", // Set the desired max height
      overflow: "Y",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#FCDD00",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20,
    }),
  };

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if (newlimit?.length > 0) {
      setpage(newlimit);
    }
  }, []);

  useEffect(() => {
    const newcorporateID = searchParams.get("corporateID");
    if (newcorporateID?.length > 0 && newcorporateID !== null) {
      setSelectedCorporate(newcorporateID);
    }
  }, []);

  useEffect(() => {
    const newpage = searchParams.get("page");
    if (newpage?.length > 0 && newpage !== null) {
      setselectpage(newpage - 1);
    }
  }, []);

  const [showdelete, setshowdelete] = useState(false);
  useEffect(() => {
    if (selectedCorporate?.length > 0) {
      setshowdelete(true);
    } else {
      setshowdelete(false);
    }
  }, [selectedCorporate]);

  const handlecorporatedelete = () => {
    searchParams.delete("corporateID");
    setSelectedCorporate("");
    setSearchParams(searchParams);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.corporateID;
    dispatch(GetEmployeelist({ ...object }));
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //for pagination
  const reactPaginate = {
    previousLabel: "<",
    nextLabel: ">",
    breakLabel: "",
    pageCount: Itemperpage,
    onPageChange: handlePageChange,
    containerClassName: "pagination",
    previousLinkClassName: "pagination__link",
    nextLinkClassName: "pagination__link",
    disabledClassName: "pagination__link--disabled",
    activeClassName: "pagination__link--active",
    forcePage: selectpage,
  };

    // for loader
    const loader = useSelector((state) => state?.Employelists?.employeeloader);

    const [editEmpModel, setEditEmpModel] = useState(false);
    const [userDetail , setuserDetail] = useState("");
    const handleEdit = (_id) => {
      // dispatch(GetCorporateDetail(_id));
      setEditEmpModel(true);
      // setEditId(_id);
    };
  return (
    <>
      {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}
      <AdminLayout>
        <div className="flex flex-col h-full px-5 py-[20px] xl:py-[30px] 2xl:justify-between">
          {/* {/ --------------------------------------------Search Bar---------------------------------------------------------- /} */}
          <div className="bg-white p-4 rounded-lg  w-full mb-5">
            <div className=" md:flex gap-3 md:gap-0 justify-between items-center">
              <div className="relative">
                <div className="absolute top-[8px] left-[20px] text-gray-400 text-2xl">
                  <RiSearch2Line className="w-6 h-6" />
                </div>
                <form
                  onSubmit={(e) => {
                    e?.preventDefault();
                    if (Search.trim() !== "") {
                      submitsearch(e);
                    }
                  }}
                >
                  <input
                    className="h-full bg-[#FFFCE1] border rounded-[8px] py-2 pl-[50px] w-[250px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium outline-[#FCDD00]"
                    placeholder="Search By Employee Name"
                    value={Search}
                    type="text"
                    onChange={(e) => {
                      const inputValue = e.target.value.trimStart();
                      setSearch(inputValue);
                      if (inputValue.trim() === "") {
                        removeQueryParams();
                      }
                    }}
                  />
                </form>
              </div>
              <div className="w-64  flex items-center md:justify-end rounded-[8px] gap-4 mt-3 md:mt-0">
                {showdelete && (
                  <div className="flex gap-3">
                    {/* {Employeelist?.employeeData?.length === 0 ? (<button
                      className="bg-[#FCDD00] py-1 px-3  rounded-[4px] del-tooltip h-[40px] w-[54px] hidden"
                      onClick={() => handelMail(corporateID)}
                    >
                      <AiOutlineMail />
                    </button>) : (<button
                      className="bg-[#FCDD00] py-1 px-3  rounded-[4px] del-tooltip h-[40px] w-[54px]"
                      onClick={() => handelMail(corporateID)}
                    >
                      <AiOutlineMail />
                      <span className="del-tooltiptext !z-20">Send Mail</span>
                    </button>)} */}

                    {/* <button
                    className="bg-[#FCDD00] py-1 px-3  rounded-[4px] del-tooltip h-[40px] w-[54px]"
                    onClick={() => handelMail(corporateID)}
                  >
                    <AiOutlineMail/>
                    <span className="del-tooltiptext">Send Mail</span>
                  </button> */}
                    <button
                      className="bg-[#FCDD00] py-1 px-3  rounded-[4px] del-tooltip"
                      onClick={handlecorporatedelete}
                    >
                      <img src={ClearFilter} alt="new"></img>
                      <span className="del-tooltiptext !z-20">Clear Filter</span>
                    </button>
                  </div>
                )}
                <Select
                  className="w-[180px]"
                  options={optionList?.map((option) => ({
                    ...option,
                    label:
                      option.label.length > 17
                        ? option.label.slice(0, 17) + "..."
                        : option.label,
                  }))}
                  placeholder="Select Corporate"
                  value={optionList?.filter(
                    (option) => option.value === selectedCorporate
                  )}
                  onChange={handleSelect}
                  isSearchable={true}
                  styles={customStyles}
                />
              </div>
              {error && <p className="text-[#ff0000]">{error}</p>}
            </div>
          </div>
          {/* {/ --------------------------------------------Content Table------------------------------------------------------- /} */}
          <div className="w-full h-[520px] xl:h-[610px] overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
            {Employeelist?.employeeData?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <p className="font-Roboto text-xl ">
                  No Employee Details Available.
                </p>
              </div>
            ) : (
              <table className="corporate-table w-[100%]  text-left font-Poppins">
                <thead>
                  <tr style={{ zIndex: 10 }}>
                    <th>
                      <div className="pl-[40px] py-3 xl:py-5 min-w-[200px]">
                        Corporate Name
                      </div>
                    </th>
                    <th>
                      <div className="min-w-[200px]">Employee Name</div>
                    </th>
                    <th>
                      <div className="min-w-[250px]">Email Id</div>
                    </th>
                    <th>
                      <div className="min-w-[160px]">Contact No.</div>
                    </th>
                    <th>
                      <div className="min-w-[130px]">Status</div>
                    </th>
                    <th>
                      <div className="min-w-[120px]">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Employeelist?.employeeData?.map((user, index) => (
                    <tr key={index}>
                      <td className="pl-[40px] py-2 xl:py-4 h-[46px]">
                        {user?.corporateName && user.corporateName[0] ? (
                          user.corporateName[0].length > 30 ? (
                            <div className="tooltip">
                              <span>
                                {user.corporateName[0].substring(0, 30)}...
                              </span>
                              <span className="tooltiptext">
                                {user.corporateName[0]}
                              </span>
                            </div>
                          ) : (
                            <span>{user.corporateName[0]}</span>
                          )
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      {user?.name?.length > 30 ? (
                        <td>
                          <div className="tooltip capitalize">
                            {`${user?.name?.substring(0, 30)}...`}
                            <span className="tooltiptext">{user.name}</span>
                          </div>
                        </td>
                      ) : (
                        <td className="capitalize">
                          <div>{user.name}</div>
                        </td>
                      )}

                      {user?.email?.length > 35 ? (
                        <td>
                          <div className="tooltip">
                            {`${user?.email?.substring(0, 35)}...`}
                            <span className="tooltiptext">{user.email}</span>
                          </div>
                        </td>
                      ) : (
                        <td>
                          <div>{user.email}</div>
                        </td>
                      )}

                      <td>{user.contact}</td>
                      <td>
                        {user.corporatestatus &&
                          user.corporatestatus[0] === true ? (
                          <div className=" flex">
                            {/* Code block starts */}
                            <div className="cursor-pointer rounded-full bg-gray-200 relative shadow-sm">
                              <input
                                type="checkbox"
                                name="toggle"
                                id={user?._id}
                                defaultChecked={user.status == true}
                                role="switch"
                                onChange={(e) => {
                                  if (user?.status == true) {
                                    setcheckvalue(false);
                                  } else {
                                    setcheckvalue(true);
                                  }
                                  const sds = location.search
                                    .slice(1)
                                    .split("&");
                                  let object = {};
                                  if (location.search.includes("?")) {
                                    sds.map((item) => {
                                      let newItem = item.split("=");
                                      object[newItem[0]] = newItem[1];
                                    });
                                  }
                                  dispatch(
                                    UpdateEmployee(
                                      user?._id,
                                      user?.status == true ? false : true,
                                      user?.corporateID,
                                      { ...object, search: Search }
                                    )
                                  );
                                }}
                                value={checkvalue}
                                className={`${user.status == false ? "left-0" : "right-0"
                                  } focus:outline-none checkbox w-[22px] h-[22px] mx-[3px] rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto`}
                              />
                              <label
                                htmlFor={user?._id}
                                className={`${user.status == true
                                    ? "bg-[#fcdd00]"
                                    : "bg-[#dfdfdf]"
                                  } block w-[50px] r-2 h-[26px] overflow-hidden rounded-full  cursor-pointer`}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className=" flex tooltip">
                            <span className="tooltiptext">
                              This corporate status has been deactivated.
                            </span>
                            {/* Code block starts */}
                            <div className="cursor-pointer rounded-full bg-gray-200 relative shadow-sm">
                              <input
                                type="checkbox"
                                name="toggle"
                                id={user?._id}
                                defaultChecked={user.status == true}
                                role="switch"
                                value={checkvalue}
                                className={`${user.status == false ? "left-0" : "right-0"
                                  } focus:outline-none checkbox w-[22px] h-[22px] mx-[3px] rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto`}
                              />
                              <label
                                htmlFor={user?._id}
                                className={`${user.status == true
                                    ? "bg-[#fcdd00]"
                                    : "bg-[#dfdfdf]"
                                  } block w-[50px] r-2 h-[26px] overflow-hidden rounded-full  cursor-pointer`}
                              />
                            </div>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="flex gap-3">
                          {/* mail btn  */}
                          <div>
                          <span className="text-xl">
                                <BiEdit
                                  className="cursor-pointer"
                                  onClick={() => {handleEdit(user?._id)
                                    setuserDetail(user)
                                  }}
                                />
                              </span>
                                  </div>
                          <div> 
                          {user.corporatestatus &&
                                user.corporatestatus[0] === true && user?.status === true  ? (
                            <span className="text-xl">
                              <AiOutlineMail
                                className="cursor-pointer"
                                onClick={() => handelMail(user?._id)}
                              />
                            </span>
                          ) : (
                            <div className="tooltip">
                              <span className="text-xl">
                                <AiOutlineMail className="cursor-pointer" />
                              </span>
                              <span className="tooltiptext">
                              {user.corporatestatus && user.corporatestatus[0] !== true
                                    ? 'This Corporate status has been deactivated.'
                                    : 'This Employee status has been deactivated.'}
                              </span>
                            </div>
                          )}
                        </div>

                          {/* delete btn  */}
                        <div className=" hover:text-[#FF0000]">
                          <span className="text-xl">
                            {user.corporatestatus &&
                              user.corporatestatus[0] === true && user?.status === true  ? (
                              <AiOutlineDelete
                                className=" cursor-pointer"
                                onClick={() => handleDelete(user?._id)}
                              />
                            ) : (
                              <div className="tooltip text-sm">
                                <span className="text-xl">
                                  <AiOutlineDelete className=" cursor-pointer" />
                                </span>
                                <span className="tooltiptext">
                                {user.corporatestatus && user.corporatestatus[0] !== true
                                    ? 'This Corporate status has been deactivated.'
                                    : 'This Employee status has been deactivated.'}
                                </span>
                              </div>
                            )}
                          </span>
                        </div>
                        </div>
                        
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <ToastContainer />
          </div>
          {/* {/ --------------------------------------------Footer---------------------------------------------------------- /} */}
          <div className="footer-content mt-5 ">
            <div className=" md:flex justify-between items-center ">
              <div className="flex gap-2 items-center mb-3 md:mb-0">
                <p className="capitalize">View</p>
                <div className="">
                  <Menu as="div" className="relative text-left">
                    <div>
                      <Menu.Button className="flex px-[10px] ml-[24px] min-h-[37px] bg-white border-2 border-[#F2F2F2] min-w-[60px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                        <p className="text-[#47505F] font-medium font-[poppins]">
                          {page}
                        </p>
                        <img src={LimitArrow} alt="LimitArrow" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[70%] rounded-[12px] bg-white shadoe-sm focus:outline-none border  mb-2">
                        {itemsPerPageOptions?.map((e) => (
                          <div key={e?.id}>
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={classNames(
                                    active ? "bg-[#FFFCE1] text-[#1A1A1A]" : "",
                                    "block px-2 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={() => onselectedpage(e)}
                                >
                                  {e?.value}
                                </p>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              {/* {/ --------------------------------------------Footer Pagination---------------------------------------------------------- */}
              <div className="flex items-center mt-3 md:mt-0">
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleFirstPage}
                >
                  &lt;&lt;
                </button>
                <div className="hidden md:block">
                  <ReactPaginate
                    {...reactPaginate}
                    breakLabel="..."
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                  />
                </div>
                <div className="md:hidden">
                  <ReactPaginate
                    {...reactPaginate}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={1}
                  />
                </div>
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleLastPage}
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
          </div>
        </div>
        {editEmpModel && <EditEmployeeModal {...{ setEditEmpModel, userDetail }} />}
      </AdminLayout>
    </>
  );
};
export default Employee;

import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// action type file
export const actionTypes = {
  EMPLOYE_LIST: "EMPLOYE_LIST",
  HR_EMPLOYE_LIST: "HR_EMPLOYE_LIST",
  CORPORATE_EMPLOYE_LIST: "CORPORATE_EMPLOYE_LIST",
  LOADER: "LOADER",
  DELETE_HR_EMPLOYEE: "DELETE_HR_EMPLOYEE",
  EMP_MAIL_STATUS: "EMP_MAIL_STATUS",
  EXPORT_DATA: "EXPORT_DATA",
};

export const EmployeeLoader = () => {
  return {
    type: actionTypes.LOADER,
    employeeloader: true,
  };
};

// Employee List

export const GetEmployeelist = (query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/employee`, {
        params: { ...query },
        headers: {
          "X-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.EMPLOYE_LIST,
          EmployeList: response.data,
          employeeloader: false,
        });
      })
      .catch((err) => {
        // console.log(err)
      });
  };
};
// for update employee status
export const UpdateEmployee = (id, status, corporateID, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
    corporateID: corporateID,
  };

  return async (dispatch) => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/admin/employee/${id}`, data, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetEmployeelist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// for delete Employee

export const DeleteEmployee = (index, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/admin/employee/${index}`, {
        headers: {
          "X-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetEmployeelist(query));
        dispatch({
          type: actionTypes.LOADER,
          loader: false,
        });
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: actionTypes.LOADER,
            loader: false,
          });
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

//hr Employee List

export const GetHREmployeelist = (corporate, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/hr/employee?corporateID=${corporate}`,
        {
          params: { ...query },
          headers: {
            "X-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.HR_EMPLOYE_LIST,
          HrEmployeList: response.data,
          employeeloader: false,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// delete  hr Employee

export const DeleteHrEmployee = (id, query, corporate) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/hr/employee/${id}`, {
        headers: {
          "X-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.DELETE_HR_EMPLOYEE,
          deletehremp: response?.data,
        });
        dispatch(GetHREmployeelist(corporate, query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

// for update Hr employee status

export const UpdateHrEmployee = (id, status, corporateID, query, corporate) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
    corporateID: corporateID,
  };
  return async (dispatch) => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/hr/employee/${id}`, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetHREmployeelist(corporate, query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

//corporate employee list

export const GetCorporateEmployeeList = (corporate, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/corporate/employee?corporateID=${corporate}`,
        {
          params: { ...query },
          headers: {
            "X-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.CORPORATE_EMPLOYE_LIST,
          CorporateEmployeList: response.data,
          employeeloader: false,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// delete Corporate Employee

export const DeleteCorporateEmployee = (index, corporateID, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/corporate/employee/${index}`,
        {
          headers: {
            "X-access-token": token,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
        dispatch(GetCorporateEmployeeList(corporateID, query));
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// update corporate employee status

export const UpdateCorporateEmployee = (
  id,
  status,
  corporateID,
  query,
  corporate
) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
    corporateID: corporateID,
  };
  return async (dispatch) => {
    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/corporate/employee/${id}`,
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch(GetCorporateEmployeeList(corporate, query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

//for mail

export const EmpMailStatus = (empID, corporateID, hrID) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const requestData = {
    corporateID: corporateID,
    hrID: hrID,
  };

  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/employee/email/${empID}`,
        requestData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.EMP_MAIL_STATUS,
          EmpMailStatus: response.data,
          employeeloader: false,
        });
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: actionTypes.LOADER,
            employeeloader: false,
          });
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

// update Admin employee status

export const UpdateAdminEmployee = (name, email, contact, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    name: name,
    email: email,
    contact: contact,
  };
  return async (dispatch) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/employee/update`,
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch(GetEmployeelist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// Export employee data

export const ExportAdminEmployeeData = (corporateID, startdate, endDate) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/employee/excel?corporateID=${corporateID}&startdate=${startdate}&endDate=${endDate}`,
        { responseType: 'blob' },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `EmployeeData.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch({
          type: actionTypes.EXPORT_DATA,
          ExportData: response.data,
          employeeloader: false,
        });
        toast.success('Data exported successfully.', {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.EXPORT_DATA,
          employeeloader: false,
        });
        if(err.response.status === 400 || err.response.status === 500){
          toast.error('No data found.', {
            theme: "colored",
            autoClose: 5000,
          });
        }
      }
      );
  };
};

import axios from "axios";
import { toast } from "react-toastify";
//action type file
export const actionTypes = {
    SELECTED_IMAGE: "SELECTED_IMAGE",
    NEW_IMAGE_LIST: " NEW_IMAGE_LIST",
    SINGLE_IMAGE: "SINGLE_IMAGE",
    LOADER: "LOADER",
}

export const selectedImage = (file) => ({
    type: actionTypes.SELECTED_IMAGE,
    payload: file,
});
export const imageLoader =() => {
  return{
    type: actionTypes.LOADER,
    imageloader: true,
  }
}

// for upload image
export const uploadImage = (formData, token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/image`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    toast.success(response.data.message, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch(GetImageList())
    dispatch({
      type:actionTypes.SELECTED_IMAGE,
      selectedimage: response.data,
      imageloader:false
    })
  } catch (error) {
    toast.error(error.response.data.error, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch({
      type:actionTypes.LOADER,
      imageloader:false
    })
  }
};

// get img list

export const GetImageList = (query) => {
    const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/image`,{
        params: { ...query },
        headers:{
            "x-access-token": token,
        },
      })
    .then((response) =>{
        dispatch({
            type:actionTypes.NEW_IMAGE_LIST,
            Imglist: response.data,
        })
      })
    .catch((err)=>{
        console.log(err);
    })
  }

}

// delete image 

export const DeleteImage = (_id, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/image/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetImageList(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

//single image 
export const SingleImage = (_id, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/image/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type:actionTypes.SINGLE_IMAGE,
          singleIamge: response.data,
      })
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import react icon
import { MdClose } from "react-icons/md";
//import action file for lab test
import { EditLabTest } from "../../../Redux/Action/LabTestAction";
//import action file for doctor
import { DoctorLoader, EditDoctor } from "../../../Redux/Action/DoctorAction";
//import action file for corporate
import {
  CorporateLoader,
  EditCorporate,
} from "../../../Redux/Action/CorporateAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import upload from "../../../images/Upload.svg"

//****************************************************edit lab model
export const LabEditModel = ({ setEditModel, EditId }) => {
  const dispatch = useDispatch();
  const [editname, setEditName] = useState("");
  const [editurl, setEditUrl] = useState("");
  const [images, setImages] = useState([]);
  const [imageURLs, setImagesURLs] = useState([]);

  let errorsObj = { uploadimage: "", image: "" };
  const [errors, setErrors] = useState(errorsObj);

  const LabDetail = useSelector((state) => state?.AddLabTest?.labdetail?.data);

  useEffect(() => {
    setEditName(LabDetail?.labtest?.name);
    setEditUrl(LabDetail?.labtest?.url);
    setImagesURLs(`${LabDetail?.storageurl}${LabDetail?.labtest?.image}`);
  }, [LabDetail]);

  useEffect(() => {
    if (images && images.length > 0) {
      const newImageURLs = [];
      images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
      setImagesURLs(newImageURLs);
    }
  }, [images]);

  const onimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    const file = e?.target?.files[0];

    if (file) {
      const fileType = file.type;
      if (fileType === "image/gif") {
        errorObj.uploadimage = "Please upload an image with the .jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.webp extension";
        setErrors(errorObj);
        return;
      }
      if (file.size > 5000000) {
        errorObj.uploadimage = "The file should not be greater than 5 MB.";
        setErrors(errorObj);
        return;
      }
    }
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        errorObj.uploadimage = "";
        setErrors(errorObj);
        setImages([file]);
      };
    }

    if (error) {
      return;
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setErrors("");
    dispatch(EditLabTest(EditId, editname, editurl, images[0]));
  };
  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
        <div className="w-[350px] sm:w-[450px]   bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Edit Lab Details</div>
            <button
              onClick={() => {
                setEditModel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleUpdate}>
            <div className="p-3 xl:p-5 flex flex-col gap-5">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Your Name"
                    id="name"
                    name="name"
                    value={editname}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /[^a-zA-Z0-9\s]/g,
                        ""
                      ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setEditName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    URL<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="url"
                    placeholder="Enter Your URL"
                    value={editurl}
                    onChange={(e) => setEditUrl(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Edit Logo<span className="text-[#fc0000]">*</span> :
                  </span>
                  <div className="w-[110px] h-[110px]">
                  <label htmlFor="inputTag">
                    <div className="bg-[#fff] w-[100px] h-[100px] xl:h-[105px] xl:w-[105px] border-2 border-dashed border-[#FCDD00] flex items-center justify-center cursor-pointer overflow-hidden  rounded-[10px] mt-[6px]">
                      <input
                        id="inputTag"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        className="hidden"
                        onChange={onimagechange}
                      />
                      {imageURLs?.length > 0 ? (
                        <div className="w-full h-full relative group p-1">
                          <img
                            src={imageURLs}
                            className="w-full h-full rounded-[6px]"
                            alt="new"
                          ></img>
                          <div className="">
                            <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                              <img src={upload} className="mx-auto" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex justify-center items-center h-full">
                          <div className=" text-center">
                            <img src={upload} className="mx-auto" />
                            <p className="text-[#FCDD00] text-sm font-medium font-poppins">
                              Upload Image
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </label>
                  </div>
                  
                  {errors.uploadimage && (
                    <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                      {errors.uploadimage}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer w-[100px] outline-none"
                >
                  save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

//********************************************************edit doctor model
export const DocEditModel = ({ setEditDocModel, EditId }) => {
  const dispatch = useDispatch();
  const [editname, setEditName] = useState();
  const [editEmail, setEditEmail] = useState();
  const [editContactno, setEditContactno] = useState();
  const [editAddress, setEditAddress] = useState();
  const [images, setImages] = useState([]);
  const [imageURLs, setImagesURLs] = useState([]);
  const [editQualification, setEditQualification] = useState();
  const [ImageValidationError, setImageValidationError] = useState("");

  let errorsObj = { uploadimage: "", image: "" };
  const [errors, setErrors] = useState(errorsObj);

  const DoctorDetail = useSelector(
    (state) => state?.DoctorData?.doctorDetail?.data
  );
  useEffect(() => {
    setEditName(DoctorDetail?.doctorData?.name);
    setEditQualification(DoctorDetail?.doctorData?.qualification);
    setEditEmail(DoctorDetail?.doctorData?.email);
    setEditContactno(DoctorDetail?.doctorData?.contactno);
    setEditAddress(DoctorDetail?.doctorData?.address);
    setImagesURLs(
      `${DoctorDetail?.storageurl}${DoctorDetail?.doctorData?.profilepic}`
    );
  }, [DoctorDetail]);

  useEffect(() => {
    if (images && images.length > 0) {
      const newImageURLs = [];
      images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
      setImagesURLs(newImageURLs);
    }
  }, [images]);

  const onimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    const file = e?.target?.files[0];

    if (file) {
      const fileType = file.type;
      if (fileType === "image/gif") {
        errorObj.uploadimage =
          "Please upload an image with the .jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.webp extension";
        setErrors(errorObj);
        // resetImageState();
        return;
      }
      if (file.size > 5000000) {
        errorObj.uploadimage = "The file should not be greater than 5 MB.";
        setErrors(errorObj);
        // resetImageState();
        return;
      }
    }

    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        errorObj.uploadimage = "";
        setErrors(errorObj);
        setImages([file]);
        setImageValidationError("");
      };

      image.onerror = () => {
        errorObj.uploadimage = "Invalid image file.";
        setErrors(errorObj);
        error = true;
      };
    }

    if (error) {
      return;
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setErrors("");
    dispatch(
      EditDoctor(
        EditId,
        editname,
        editQualification,
        editEmail,
        editContactno,
        editAddress,
        images[0]
      )
    );
    dispatch(DoctorLoader());
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-30 bg-opacity-50">
        <div className="bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Edit Doctor Detail</div>
            <button
              onClick={() => {
                setEditDocModel(false);
                setErrors("");
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleUpdate}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Doctor Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Doctor Name"
                    value={editname}
                    onInput={(e) =>
                      (e.target.value = e.target.value
                        .replace(/[^a-zA-Z0-9\s.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1"))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setEditName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Qualification<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Qualification"
                    id="name"
                    name="name"
                    value={editQualification}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /[^a-zA-Z\s.]/g,
                        ""
                      ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setEditQualification(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Email Id<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="email"
                    placeholder="Enter Email Id"
                    id="email"
                    name="email"
                    value={editEmail}
                    onChange={(e) => setEditEmail(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Contact No<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="w-[300px] py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Contact No"
                    id="contact"
                    name="contact"
                    value={editContactno}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => setEditContactno(e.target.value)}
                    required
                    minLength={0}
                    // maxLength={10}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Address<span className="text-[#fc0000]">*</span> :
                  </span>
                  <textarea
                    className="py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Address"
                    value={editAddress}
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setEditAddress(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={100}
                    rows={4}
                  />
                </div>

                <div className="">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Edit Profile Picture
                    <span className="text-[#fc0000]">*</span> :
                  </span>
                  <label htmlFor="inputTag">
                    <div className="bg-[#fff] w-[100px] h-[100px] xl:h-[105px] xl:w-[105px] border-2 border-dashed border-[#FCDD00] flex items-center justify-center cursor-pointer overflow-hidden  rounded-[10px] mt-[6px]">
                      <input
                        id="inputTag"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        className="hidden"
                        onChange={onimagechange}
                      />
                      {imageURLs?.length > 0 ? (
                        <div className="w-full h-full relative group p-1">
                          <img
                            src={imageURLs}
                            className="w-full h-full rounded-[6px]"
                            alt="new"
                          ></img>
                          <div className="">
                            <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                              <img src={upload} className="mx-auto" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex justify-center items-center h-full">
                          <div className=" text-center">
                            <img src={upload} className="mx-auto" />
                            <p className="text-[#FCDD00] text-sm font-medium font-poppins">
                              Upload Image
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {errors.uploadimage && (
                      <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                        {errors.uploadimage}
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="w-[100px] bg-[#FCDD00] text-[#1A1A1A]  px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

//*******************************************************edit Corporate model
export const CorpEditModel = ({ setEditCorpModel, EditId,selectpage }) => {
  const dispatch = useDispatch();

  const [editCorporateName, setEditCorporateName] = useState();
  const [editname, setEditName] = useState();
  const [editEmail, setEditEmail] = useState();
  const [editContactno, setEditContactno] = useState();
  const [images, setImages] = useState([]);
  const [imageURLs, setImagesURLs] = useState([]);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  let errorsObj = { uploadimage: "", image: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [ImageValidationError, setImageValidationError] = useState("");
  const [hContact , setHContact] = useState("");
  const [aContact , setAContact] = useState("");

  const CorporateDetail = useSelector(
    (state) => state?.corporateData?.CorporateDetail?.data
  );

  useEffect(() => {
    if (CorporateDetail) {
      setEditName(CorporateDetail?.corporateData?.name);
      setEditCorporateName(CorporateDetail?.corporateData?.corporateName);
      setEditEmail(CorporateDetail?.corporateData?.email);
      setEditContactno(CorporateDetail?.corporateData?.contactno);
      setImagesURLs(
        `${CorporateDetail?.storageurl}${CorporateDetail?.corporateData?.logo}`
      );

      if (isNaN(CorporateDetail?.corporateData?.startdate)) {
        setStartDate("");
      } else {
        const startDateTimestamp = CorporateDetail?.corporateData?.startdate;
        const startDate = startDateTimestamp
          ? new Date(parseInt(startDateTimestamp))
          : "";
      
        setStartDate(startDate);
      }

      if(isNaN(CorporateDetail?.corporateData?.enddate)){
        setEndDate("")
      } else{
        const endDateTimestamp = CorporateDetail?.corporateData?.enddate;
        const endDate = endDateTimestamp
        ? new Date(parseInt(endDateTimestamp))
        : "";
        setEndDate(endDate)
      }  
      setPrimary(CorporateDetail?.corporateData?.primary);
      setSecondary(CorporateDetail?.corporateData?.secondary);
      setHContact(CorporateDetail?.corporateData?.homeopathyContact);
      setAContact(CorporateDetail?.corporateData?.alopathyContact);
    }
  }, [CorporateDetail]);

  useEffect(() => {
    if (images && images.length > 0) {
      const newImageURLs = [];
      images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
      setImagesURLs(newImageURLs);
    }
  }, [images]);

  const onimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    const file = e?.target?.files[0];

    if (file) {
      const fileType = file.type;
      if (fileType === "image/gif") {
        errorObj.uploadimage =
          "Please upload an image with the .jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.webp extension";
        setErrors(errorObj);
        error = true;
        return;
      }
      if (file.size > 5000000) {
        errorObj.uploadimage = "The file should not be greater than 5 MB.";
        setErrors(errorObj);
        error = true;
        return;
      }
    }

    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        errorObj.uploadimage = "";
        setErrors(errorObj);
        setImages([file]);
        setImageValidationError("");
      };

      image.onerror = () => {
        errorObj.uploadimage = "Invalid image file.";
        setErrors(errorObj);
        error = true;
      };
    }
    if (error) {
      return;
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (startDate && !endDate) {
      setError("Please select an End date.");
      return;
    }
    if (!startDate && endDate) {
      setError("Please select an Start date.");
      return;
    }
    if (errorsObj.uploadimage) {
      return;
    }
    dispatch(
      EditCorporate(
        EditId,
        editCorporateName,
        editname,
        editEmail,
        editContactno,
        Math.floor(new Date(startDate).getTime()),
        Math.floor(new Date(endDate).getTime()),
        primary,
        secondary,
        images[0],
        selectpage, 
        hContact,
        aContact
      )
    );
    
    dispatch(CorporateLoader());
    // console.log(selectpage,"select");
  };

  const handleStartDateChange = (date) => {
    const selectedStartDate = date;
    if (endDate && selectedStartDate > endDate) {
      setError("Start date should be less than the End date.");
    } else {
      setStartDate(selectedStartDate);
      setError("");
    }
  };

  const handleEndDateChange = (date) => {
    const selectedEndDate = date;
    if (startDate && selectedEndDate < startDate) {
      setError("End date should be greater than the Start date.");
      setEndDate("");
    } else {
      setEndDate(selectedEndDate);
      setError("");
    }
  };

  const handleMember = (e) => {
    const value = e.target.value;
    setPrimary(value);
    setSecondary(value ? value * 4 : "");
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-30 bg-opacity-50">
        <div className="bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Edit Corporate</div>
            <button
              onClick={() => {
                setEditCorpModel(false);
                setHContact("");
                setAContact("");
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleUpdate}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Corporate Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Corporate Name"
                    id="corporateName"
                    name="corporateName"
                    value={editCorporateName}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /[^a-zA-Z0-9\s]/g,
                        ""
                      ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setEditCorporateName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Name"
                    id="name"
                    name="name"
                    value={editname}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /[^a-zA-Z0-9\s]/g,
                        ""
                      ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setEditName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Email Id<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="email"
                    placeholder="Enter Corporate Email Id"
                    id="email"
                    name="email"
                    value={editEmail}
                    onChange={(e) => setEditEmail(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Contact No<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] w-[300px] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Corporate Contact No"
                    id="contact"
                    name="contact"
                    value={editContactno}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => setEditContactno(e.target.value)}
                    required
                    autoComplete="off"
                    // maxLength={10}
                    minLength={0}
                  />
                </div>
              </div>

              <div className="md:flex gap-3">
                <div>
                  {/* dates  */}
                  <div className="flex gap-3">
                    {/* start  */}
                    <div className="flex flex-col gap-1">
                      <span className="font-Poppins text-[15px] xl:text-base">
                        Start Date :
                      </span>
                      <DatePicker
                        className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] uppercase input-container outline-[#FCDD00]"
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD-MM-YYYY"
                        showPopperArrow={false}
                        popperClassName="some-custom-class"
                        popperPlacement="top-start"
                        popperModifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [5, 10],
                              },
                            },
                            {
                              name: "preventOverflow",
                              options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                              },
                            },
                          ]}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-Poppins text-[15px] xl:text-base">
                        End Date :
                      </span>
                      <DatePicker
                        className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] uppercase input-container outline-[#FCDD00]"
                        selected={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD-MM-YYYY"
                        showPopperArrow={false}
                        popperClassName="some-custom-class"
                        popperPlacement="top-start"
                        popperModifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [5, 10],
                              },
                            },
                            {
                              name: "preventOverflow",
                              options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                              },
                            },
                          ]}
                      />
                    </div>
                  </div>

                  <div className="flex gap-3 mt-2">
                    <div className="flex flex-col gap-1">
                      <span className="font-Poppins text-[15px] xl:text-base">
                        Member Limit :
                      </span>
                      <div className="flex gap-3">
                        <input
                          type="number"
                          className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] outline-[#FCDD00]"
                          value={primary}
                          onChange={handleMember}
                          placeholder="Primary"
                          min={1}
                        />

                        <input
                          type="text"
                          className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] outline-[#FCDD00]"
                          value={secondary}
                          readOnly
                          placeholder="Secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Edit Logo<span className="text-[#fc0000]">*</span> :
                  </span>
                  <label htmlFor="inputTag">
                    <div className="bg-[#fff] w-[100px] h-[100px] xl:h-[105px] xl:w-[105px] border-2 border-dashed border-[#FCDD00] flex items-center justify-center cursor-pointer overflow-hidden  rounded-[10px] mt-[6px]">
                      <input
                        id="inputTag"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        // accept="image/*"
                        className="hidden"
                        onChange={onimagechange}
                      />
                      {imageURLs?.length > 0 ? (
                        <div className="w-full h-full relative group p-1">
                          <img
                            src={imageURLs}
                            className="w-full h-full rounded-[6px]"
                            alt="new"
                          ></img>
                          <div className="">
                            <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                              <img src={upload} className="mx-auto" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex justify-center items-center h-full">
                          <div className=" text-center">
                            <img src={upload} className="mx-auto" />
                            <p className="text-[#FCDD00] text-sm font-medium font-poppins">
                              Upload Image
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {errors.uploadimage && (
                      <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                        {errors.uploadimage}
                      </div>
                    )}
                    {ImageValidationError && (
                      <p className="mt-2 text-[#FF0101] text-sm font-poppins">
                        {ImageValidationError}
                      </p>
                    )}
                  </label>
                </div>
              </div>
        <div>
                        {error && (
                          <p className="mt-2 text-[#FF0101] text-sm font-poppins">
                            {error}
                          </p>
                        )}
                      </div>
              <div className="md:flex justify-between md:items-end">
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Homeopathy Contact<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm md:w-[220px] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Homeopathy Contact"
                    value={hContact}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => setHContact(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1 mt-2 md:mt-0">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Alopathy Contact<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] md:w-[220px] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Alopathy Contact"
                    id="contact"
                    name="contact"
                    value={aContact}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => {
                      setAContact(e.target.value);
                    }}
                    required
                    autoComplete="off"
                    minLength={0}
                  />
                </div>
              </div>
                <div className="flex justify-end">
                  <button
                    onSubmit={handleUpdate}
                    type="submit"
                    className="bg-[#FCDD00] text-[#1A1A1A] w-[100px] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../component/layout/AdminLayout/AdminLayout";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { GetCorporateOptionlist } from "../../Redux/Action/CorporateAction";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import UseOutsideClick from "../../component/Admin/UseOutsideClick";
import calender from "../../images/Doctor/calender.png";
import { EmployeeLoader, ExportAdminEmployeeData } from "../../Redux/Action/EmployeeAction";
import Loader from "../../component/Admin/Loader";
import { ToastContainer } from "react-toastify";

function Export() {
  // for searchable-dropdwon css
  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Roboto",
        color: "#1A1A1A",
      };
    },
    option: (defaultStyles) => ({
      ...defaultStyles,
      fontFamily: "Roboto",
      color: "#1A1A1A",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#808080",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#808080",
    }),
    control: (base, state) => ({
      ...base,
      height: "40px",
      borderColor: state.isFocused ? "#FCDD00" : "#808080",
      "&:hover": {
        borderColor: "#FCDD00",
      },
      boxShadow: "none !important",
    }),
    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: state.isSelected ? "#1A1A1A" : state.data.color,
      backgroundColor: state.isSelected ? "#FCDD00" : "white",
      ":hover": {
        backgroundColor: "#FFFCE1",
      },
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px", // Set the desired max height
      overflow: "Y",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#FCDD00",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20,
    }),
  };

  const [optionList, setOptionList] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [openCalender, setopenCalender] = useState(false);
  const [unixstartDate, setUnixStartDate] = useState(new Date(new Date().setUTCHours(0,0,0,0))?.getTime());
  const [unixendDate, setUnixendDate] = useState(new Date(new Date().setUTCHours(23,59,59,999))?.getTime());

  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (openCalender) {
      setopenCalender(false);
    }
  });
  const dispatch = useDispatch();
  const Corporatename = useSelector(
    (state) => state?.corporateData?.CorporateOptionlist?.data
  );

  useEffect(() => {
    const con =
      Corporatename &&
      Corporatename?.map((c) => ({
        value: c.corporateID,
        label: c.corporateName,
        color: c.status ? "black" : "#808080",
      }));
    setOptionList(con);
  }, [Corporatename]);

  useEffect(() => {
    dispatch(GetCorporateOptionlist());
  }, []);

  // for Corporate
  function handleSelect(selectedOption) {
    setSelectedCorporate(selectedOption?.value);
    // const sds = location.search.slice(1).split("&");
    // let object = {};
    // if (location.search.includes("?")) {
    //   sds.map((item) => {
    //     let newItem = item.split("=");
    //     object[newItem[0]] = newItem[1];
    //   });
    //   history({
    //     pathname: "/admin/admin_user/employee",
    //     search:
    //       "?" +
    //       new URLSearchParams({
    //         ...object,
    //         corporateID: selectedOption?.value,
    //       }).toString(),
    //   });
    // } else {
    //   history({
    //     pathname: "/admin/admin_user/employee",
    //     search: `?corporateID=${selectedOption?.value}`,
    //   });
    // }
    // dispatch(
    //   GetEmployeelist({ ...object, corporateID: selectedOption?.value })
    // );
  }

  // for date picker
  function formatDate(date) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  const handleSelection = (range) => {
    setSelectionRange(range.selection);
    const startDateText = formatDate(range.selection.startDate);
    const endDateText = formatDate(range.selection.endDate);
    let unix_timestamp = new Date(
      new Date(startDateText).setUTCHours(23, 59, 59, 999)
    )?.getTime();
    let unix_timestamp1 = Math?.floor(
      new Date(endDateText)?.getTime() + 86400000
    );
    setUnixStartDate(unix_timestamp)
    setUnixendDate(unix_timestamp1)
  };

  function formattedStartDate(d) {
    let month = String(d?.getMonth() + 1);
    let day = String(d?.getDate());
    const year = String(d?.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}/${month}/${year}`;
  }

  const loader = useSelector((state) => state?.Employelists?.employeeloader);

  const handleExportData = (e) => { 
  e?.preventDefault();
  dispatch(ExportAdminEmployeeData(selectedCorporate , unixstartDate , unixendDate))
  dispatch(EmployeeLoader());
  }
  
  return (
    <div>
         {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}
            <ToastContainer />
      <AdminLayout>
        <div className="px-5 py-[30px] flex flex-col sm:flex-row sm:items-center justify-between">
          <Select
            className="w-[180px]"
            options={optionList?.map((option) => ({
              ...option,
              label:
                option.label.length > 17
                  ? option.label.slice(0, 17) + "..."
                  : option.label,
            }))}
            placeholder="Select Corporate"
            value={optionList?.filter(
              (option) => option.value === selectedCorporate
            )}
            onChange={handleSelect}
            isSearchable={true}
            styles={customStyles}
          />
          <div className=" relative mt-[10px] lg:mt-0">
            <button
              className=" bg-[#fae006] flex px-4 rounded-[8px] py-2 font-medium"
              onClick={() => setopenCalender(!openCalender)}
            >
              <img src={calender} className="mr-[12px]" alt="calenderimg"></img>
              {formattedStartDate(selectionRange?.startDate)} -{" "}
              {formattedStartDate(selectionRange?.endDate)}
            </button>
            {openCalender && (
              <div className=" absolute right-0 z-50" ref={ref}>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelection}
                  rangeColors={["#FCDD00"]}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  maxDate={new Date()}
                  popperProps={{
                    strategy: "fixed",
                  }}
                  direction="horizontal"
                />
              </div>
            )}
          </div>
        </div>
        {selectedCorporate &&<div className=" flex items-center justify-center">
          <button className=" bg-[#fae006] flex px-4 rounded-[8px] py-2 font-medium" onClick={handleExportData}>
            Export
          </button>
        </div>}
      </AdminLayout>
    </div>
  );
}

export default Export;

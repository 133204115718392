import React from 'react'
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Corporate from "./pages/Admin/Corporate";
import UploadeEmployee from "./pages/Admin/UploadeEmployee";
import Login from './component/Admin/Login';
import Hr from "./pages/Admin/Hr";
import Employee from "./pages/Admin/Employee";
import Doctor from "./pages/Admin/Doctor";
import Report from "./pages/Hr/Report";
import UploadEmployee from "./pages/Hr/UploadEmployee";
import HrEmployee from "./pages/Hr/HrEmployee";
import AdminRoute from './pages/Admin/AdminRoute';
import HrRoute from './pages/Hr/HrRoute';
import CorporateRoute from './pages/Corporate/CorporateRoute';
import CorporateHr from './pages/Corporate/CorporateHr';
import CreateEmployee from './pages/Corporate/CreateEmployee';
import CorporateEmployee from './pages/Corporate/CorporateEmployee';
import ChangePassword from './component/Admin/ChangePassword ';
import AboutUs from './component/Admin/AboutUs';
import HealthTips from './pages/Admin/HealthTips';
import Chat from './component/Admin/Chat';
import PrivacyPolicy from './component/Admin/PrivacyPolicy';
import LabTest from './pages/Admin/LabTest';
import DoctorRoute from './pages/Doctor/DoctorRoute';
import TimeSlote from './pages/Doctor/TimeSlote';
import FamilyMember from './pages/Hr/FamilyMember';
import CorporateReport from './pages/Corporate/CorporateReport';
import EmployeeList from './pages/Doctor/EmployeeList';
import Appointment from './pages/Doctor/Appointment';
import Consultation from './pages/Doctor/Consultation';
import EmpChangePassword from './component/Admin/EmpChangePassword';
import WellnessPartner from './pages/Admin/WellnessPartner';
import Panel from './pages/Admin/Panel';
import Mirajpage from './pages/Mirajpage';
import Export from './pages/Admin/Export';

function Router() {
  return (
    <Routes>
    <Route path="/" element={<Navigate to="/login" />} />
    <Route path="/login" element={<Login />} />
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/employee/changepassword/:id" element={<EmpChangePassword />} />
    <Route path="/aboutus" element={<AboutUs />} />
    <Route path="/chat" element={<Chat />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/mirajform" element={<Mirajpage />} />
    
    {/* Admin Route */}
    <Route path="/admin/admin_user/corporate" element={<AdminRoute> <Corporate/></AdminRoute>} />
    <Route path="/admin/admin_user/hr" element={<AdminRoute><Hr/></AdminRoute>} />
    <Route path="/admin/admin_user/uploadeemployee" element={<AdminRoute><UploadeEmployee/></AdminRoute>} />
    <Route path="/admin/admin_user/employee" element={<AdminRoute><Employee/></AdminRoute>} />
    <Route path="/admin/admin_user/doctor" element={<AdminRoute><Doctor/></AdminRoute>} />
    <Route path="/admin/admin_user/healthtips" element={<AdminRoute><HealthTips/></AdminRoute>} />
    <Route path="/admin/admin_user/labtest" element={<AdminRoute><LabTest/></AdminRoute>} />
    <Route path="/admin/admin_user/wellness-partner" element={<AdminRoute><WellnessPartner/></AdminRoute>} />
    <Route path="/admin/admin_user/panel" element={<AdminRoute><Panel/></AdminRoute>} />
    <Route path="/admin/export" element={<AdminRoute><Export/></AdminRoute>} />

    {/* hr Route */}
    <Route path="/hr/uploademployee" element={<HrRoute><UploadEmployee/></HrRoute>} />
    <Route path="/hr/employee" element={<HrRoute><HrEmployee/></HrRoute>} />
    <Route path="/hr/family-member" element={<HrRoute><FamilyMember/></HrRoute>} />
    <Route path="/hr/report" element={<HrRoute><Report/></HrRoute>} />

    {/* Corporate Route */}
    <Route path="/corporate/hr" element={<CorporateRoute><CorporateHr/></CorporateRoute>} />
    <Route path="/corporate/uploademployee" element={<CorporateRoute><CreateEmployee/></CorporateRoute>} />
    <Route path="/corporate/employee" element={<CorporateRoute><CorporateEmployee/></CorporateRoute>} />
    <Route path="/corporate/report" element={<CorporateRoute><CorporateReport/></CorporateRoute>} />

    {/* Doctor Route  */}
    <Route path='/doctor/time-slote' element={<DoctorRoute><TimeSlote/></DoctorRoute>} />
    <Route path='/doctor/employee-list' element={<DoctorRoute><EmployeeList/></DoctorRoute>} />
    <Route path='/doctor/appointment' element={<DoctorRoute><Appointment/></DoctorRoute>} />
    <Route path='/doctor/video-call' element={<DoctorRoute><Consultation/></DoctorRoute>} />
    
  </Routes>
  )
}

export default Router




import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi"
import { MdViewTimeline } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";
import { TbReport } from "react-icons/tb";
import appointment  from "../../../images/Doctor/appointment.svg"
import vc from "../../../images/Doctor/VideoCall.svg"

const DoctorSidebar = () => {
  return (
    <div className="lg:flex flex-no-wrap min-h-[91.8vh] hidden">
      <div className="min-w-[230px] bg-white  flex-col justify-between flex z-10">
        <div>
          <ul className="px-3 py-5 flex flex-col gap-2 min-w-[231px]">
            {/* <li>
              <NavLink
                to="/doctor/time-slote"
                className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <MdViewTimeline /> Time Slot
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/doctor/patient"
                className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <AiOutlineUserAdd /> Patient
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/doctor/employee-list"
                className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <HiUserGroup /> Employee List
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/doctor/appointment"
                className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <img src={appointment}/> Appointment
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/doctor/video-call"
                className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <img src={vc}/> VideoCall
              </NavLink>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DoctorSidebar
import React from "react";
import HrSidebar from "./HrSidebar";
import { AdminNavigation } from "../../Admin/AdminNavigation";
import { ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import UseOutsideClick from "../../Admin/UseOutsideClick";
import { RiContactsBookUploadFill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { TbReport } from "react-icons/tb";
import { MdFamilyRestroom } from "react-icons/md";                        
import close from "../../../images/Sidebar/close.svg";

const HrLayout = (props) => {
  const [sidemenu, setsidemenu] = useState(false);
  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (sidemenu) {
      setsidemenu(false);
    }
  });
  
  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);
  return (
    <>
      <div className="relative">
        <div className="sticky top-0 z-20">
          <AdminNavigation setsidemenu={setsidemenu} />
        </div>
        <div className="lg:flex">
          <div className="fixed bottom-0  top-[74px] min-h-[89.8vh]">
            <HrSidebar />
          </div>

          {/* mobile side bar */}
          <div
            className={`${
              sidemenu &&
              `${"backcolor absolute z-[1002] top-0 bottom-0 right-0 left-0"}`
            } lg:hidden`}
          >
            <div
              className={`${
                sidemenu
                  ? "translate-x-0 h-full max-w-[335px] fixed bottom-0 px-[16px] pt-[25px] pb-[15px]"
                  : "-translate-x-full "
              } transform  ease-in-out transition duration-500  bg-[#FFFFFF] flex flex-col justify-between lg:hidden`}
              ref={ref}
            >
              {sidemenu && (
                <div className="min-w-[250px] flex-col justify-between flex">
                  <div>
                    <img
                      src={close}
                      className=" ml-auto"
                      onClick={() => setsidemenu(false)}
                      alt="new"
                    ></img>
                  </div>
                  <div>
                    <ul className="px-3 py-5 flex flex-col gap-5 min-w-[273px]">
                    {newcorporate?.data?.corporateName !== 'Vibhuti' && <li>
                        <NavLink
                          to="/hr/uploademployee"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <RiContactsBookUploadFill /> Create Employee
                        </NavLink>
                      </li>}
                      <li>
                        <NavLink
                          to="/hr/employee"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <HiUserGroup /> Employee List
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/hr/family-member"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <MdFamilyRestroom /> Family Member
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/hr/report"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <TbReport /> Report
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="w-full md:pl-[40px] lg:pl-[250px] min-h-[85.7vh] md:pr-[16px] bg-[#FFFCE1]">
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default HrLayout;

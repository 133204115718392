import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const actionTypes = {
  HR_LIST: "HR_LIST",
  NEW_HR: "NEW_HR",
  CORPORATE_HR_LIST: "CORPORATE_HR_LIST",
  NEW_CORPORATE_HR: "NEW_CORPORATE_HR",
  ADD_FAMILY_MEMBER: "ADD_FAMILY_MEMBER",
  LOADER: "LOADER",
  HR_MAIL_STATUS: "HR_MAIL_STATUS",
};

export const HRLoader = () => {
  return {
    type: actionTypes.LOADER,
    hrloader: true,
  };
};
// HR list

export const GetHrlist = (query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/hr`, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.HR_LIST,
          HrList: response.data,
          hrloader: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//  create new Hr

export const CreateHr = (Data, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/admin/hr`, Data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.NEW_HR,
          NewHr: response.data,
          hrloader: false,
        });
        dispatch(GetHrlist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>{
        if(err){
          dispatch({
            type:actionTypes.LOADER,
            hrloader:false
          })
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      }
        
      );
  };
};

// delete HR

export const DeleteHr = (id, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/admin/hr/${id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetHrlist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// update Hr

export const UpdateHrStatus = (id, status, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
  };
  return async (dispatch) => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/admin/hr/${id}`, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetHrlist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// corporate hr list

export const GetCorporateHrlist = (corporate, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/corporate/hr?corporateID=${corporate}`,
        {
          params: { ...query },
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: actionTypes.CORPORATE_HR_LIST,
          CorporateHrList: response.data,
          hrloader: false,
        });
      })
      .catch((err) => {
        //  console.log(err)
      });
  };
};

//  create new Corporate Hr

export const CreateCorporateHr = (Data, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/corporate/hr`, Data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.NEW_CORPORATE_HR,
          NewCorporateHr: response.data,
          hrloader: false,
        });
        dispatch(GetCorporateHrlist(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
          hrloader: false,
        });
      })
      .catch((err) =>{
        if(err){
          dispatch({
            type: actionTypes.LOADER,
            hrloader:false,
          })
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      }
      
        
      );
  };
};

// delete corporate  HR

export const DeleteCorporateHr = (id, query, corporate) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/corporate/hr/${id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetCorporateHrlist(corporate, query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// update corporate HR status

export const UpdateCorporateHr = (id, status, query, corporate) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
  };
  return async (dispatch) => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/corporate/hr/${id}`, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetCorporateHrlist(corporate, query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

//for corporate mail 
export const HrMailStatus = (HrId,corporateID) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const requestData = {
    corporateID: corporateID,

  };

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/hr/mail/${HrId}`, requestData, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.HR_MAIL_STATUS,
          HrMailStatus: response.data,
          hrloader:false,
        });
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        if(err){
          dispatch({
            type: actionTypes.LOADER,
            hrloader:false,
          })
        }
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      }
      );
  };
};
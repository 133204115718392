export const actionTypes = {
  HR_LIST: "HR_LIST",
  NEW_HR: "NEW_HR",
  CORPORATE_HR_LIST: "CORPORATE_HR_LIST",
  NEW_CORPORATE_HR: "NEW_CORPORATE_HR",
  ADD_FAMILY_MEMBER: "ADD_FAMILY_MEMBER",
  LOADER: "LOADER",
  HR_MAIL_STATUS: "HR_MAIL_STATUS",
};

const INITIAL_STATE = {
  HrList: [],
  NewHr: [],
  CorporateHrList: [],
  NewCorporateHr: [],
  AddFamilyMember: [],
  hrloader: false,
  HrMailStatus: []
};

const HrReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOADER:
      return {
        ...state,
        hrloader: action.hrloader,
      };
    case actionTypes.HR_LIST:
      return {
        ...state,
        HrList: action.HrList,
        hrloader: action.hrloader,
      };
    case actionTypes.NEW_HR:
      return {
        ...state,
        NewHr: action.NewHr,
        hrloader: action.hrloader,
      };
    case actionTypes.CORPORATE_HR_LIST:
      return {
        ...state,
        CorporateHrList: action.CorporateHrList,
        hrloader: action.hrloader,
      };
    case actionTypes.NEW_CORPORATE_HR:
      return {
        ...state,
        NewCorporateHr: action.NewCorporateHr,
        hrloader: action.hrloader,
      };
    case actionTypes.ADD_FAMILY_MEMBER:
      return {
        ...state,
        AddFamilyMember: action.AddFamilyMember,
      };
    case actionTypes.HR_MAIL_STATUS:
      return{
        ...state,
        HrMailStatus: action.HrMailStatus,
        hrloader: action.hrloader,
      }
    default:
      return state;
  }
};

export default HrReducer;
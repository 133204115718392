import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className=" mx-auto bg-[#FFFCE1]">
      <div className="max-w-[1024px] m-auto">
        <div className="w-[100%] flex items-center justify-center">
          <div className="container py-10 flex flex-col items-center justify-between w-full px-4 ">
            <p className="text-3xl text-[#1A1A1A] pb-3 font-bold bg-white pt-2 mb-2 w-full text-center">
              Privacy Policy
            </p>
          </div>
        </div>
        <div className=" bg-[#FFFCE1] pb-10 ">
        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">1. Overview</p>
          <div>
            <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
              This website ("
              <a
                href="https://www.welcomecure.com/"
                className="hover:underline text-blue-500"
              >
                www.welcomecure.com
              </a>
              ") is a service of
              <span className="font-bold"> Welcome Cure Pvt. Ltd.</span>{" "}
              ("Welcome Cure"). Welcome Cure respects the privacy of visitors to
              this Site. Please read this Privacy Policy carefully so that you
              understand what information we collect through the Site and what
              we do with that information. This Privacy Statement applies only
              to information collected online through the Site, where specified
              and through other services within the Network, and does not
              describe the ways in which we may collect or use information
              obtained offline or through any other means. If you have any
              questions about this Privacy Statement, please contact us at{" "}
              <a
                href="mailto:askme@welcomecure.com"
                className="hover:underline text-blue-500"
              >
                askme@welcomecure.com
              </a>
              . Protecting the Privacy of Children: Children should always get
              permission from their parents before sending any information about
              themselves (such as their names, email addresses, and phone
              numbers) over the Internet, to us or to anyone else involving the
              site. Our website is not directed at children under 18 and we
              won't knowingly allow anyone under 18 to provide us any personally
              identifying information; if you're under 18, please don't provide
              any information about yourself on this website and phase and
              please ask your parents or on adult / guardian to fill on your
              behalf.. Your history taking form should have these details that
              history form filled on behalf of less than 18 year old patients by
              name, address, designation, email Id, phone number.
            </p>
          </div>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
            2. The Information We Collect and How We Use It
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5">
            The information we collect falls into two categories:
          </p>
          <div className="text-[14px] sm:text-[16px] text-justify">
            <p className=" pb-5">
              (1) information voluntarily supplied by users of the Site when
              they register with the Site, write SharePosts, post comments,
              respond to surveys or questionnaires, enter promotional contests,
              make entries in treatment diaries, make use of an assessment tool
              (such as our body mass index calculator), and the likes; and
            </p>
            <p className=" pb-5">
              (2) tracking information gathered as visitors navigate through our
              Site and review our email newsletters and other email messages. We
              also put you on notice that third party providers featured on or
              linked to / by our Site, such as advertisers of products and
              services offered through the Site, may be gathering information,
              as well, through processes we don't control.
            </p>
            <p className=" pb-5">
              <span className="font-semibold">
                A. Information Supplied by Visitors:
              </span>{" "}
              To participate in any of the community features on this Site (for
              example, by creating or subscribing to SharePosts, posting to a
              message board, commenting on a blog, etc.), you must register with
              us. When you register, we may ask you to provide certain contact
              information, such as your name, email address, postal address, or
              telephone number (collectively, "Contact Information"). We may
              request similar Contact Information when you participate in a
              survey or poll, respond to a questionnaire or quiz, enter a
              promotional contest, create a treatment diary, sign-up for a
              reminder service or content alert, make use of an assessment tool,
              or sign up for any of the other services available on the Site. If
              you don't provide us your postal address, we may use the Contact
              Information you provide to complete our records by obtaining your
              postal address from a third party and adding your postal address
              to our database as part of your Contact Information. You may
              opt-out of having your postal address collected in this way by
              e-mailing us at askme@welcomecure.com. Once you provide us Contact
              Information, you are no longer anonymous to us. We may use your
              Contact Information to contact you, for example, when we send you
              an email newsletter, a direct mail advertisement or offer, a
              treatment reminder, or an alert that you've requested. If you are
              selected as a winner of a promotional contest, we'll use the
              Contact Information you provided to contact you. When you register
              with our Site, sign up for a service, or otherwise interact with
              the Site, we may ask you to provide demographic information (such
              as your ZIP code/pincode, age, gender, and income level),
              health-related information (such as height, weight, age, gender,
              family characteristics, health-related interests, treatment
              experience, prescription drug information, etc., as well as
              information about the kinds of healthcare providers you've
              consulted and how your health services are paid for), and other
              information relating to your use of the Site (such as a username
              and password). We may use the demographic and health-related
              information you provide to improve our Site and the services we
              offer and to tailor the advertising displayed on our Site to the
              interests of individual users to increase the likelihood that our
              users will find these ads useful and informative. We may also
              share this information with advertisers, on an anonymous,
              aggregate basis, to establish for our advertisers the value of
              advertising to our audience. We may associate any demographic or
              health-related information you voluntarily provide with your
              Contact Information so that we can send you email messages about
              products or services that we believe may be of interest to you,
              provided that you have not previously opted-out of receiving such
              messages.
            </p>
            <p className=" pb-5">
              When you register, sign up for a service, or otherwise interact
              with the Site, we may also invite you to subscribe to various
              email newsletters or other services. You can unsubscribe to any
              such newsletter or service by following the instructions in
              Section 10 ("Choice/Opt-Out"), below.
            </p>
            <p className="mb-5">
              Our advertisers and other sponsors may collect information from
              users of this Site through offer forms that we display on the Site
              on their behalf. These forms are labeled as advertisements or
              identify the advertiser or other sponsor that is making the offer
              and may ask you to provide Contact Information, demographic
              information, health-related information, and other information. As
              will be apparent from the web page on which the form is displayed,
              we submit the information you provide on such forms directly to
              the advertiser or other sponsor identified on the form and we
              retain a copy of such information only for as long as is necessary
              to establish to the satisfaction of the advertiser or sponsor the
              value we have provided. The site or its owners do not guarantee or
              warranty the quality or correctness of the advertisements/
              sponsorships of the goods and services to which they pertain, so
              made in the site.
            </p>
            <p className=" pb-5">
              <span className="font-semibold">
                B. Other Information We Collect:
              </span>{" "}
              Our web servers automatically collect limited information about
              your computer configuration when you visit our Site, including the
              type (and version) of browser software you use, the operating
              system you're running, the website that referred you, and your IP
              address. We use this information to make our site as useful as we
              can for as many users as possible. Your IP address is a numerical
              address assigned to your computer that is used by other computers
              connected to the Internet to communicate with your computer so
              that data (such as the web pages you want to view) can be
              transmitted to you. We use your IP address to route data (such as
              the web pages you request) to your computer, to track which pages
              within the Site have been accessed from your computer, and to
              track how well each page within our Site is performing so that we
              can continue to improve our Site and the services we offer. We
              also use IP address information for systems administration, to
              report aggregate information to sponsors and advertisers, and to
              conduct Site analysis.
            </p>
            <p className="pb-5">
              Your web browser software includes a feature called "cookies" that
              allows the websites you visit to store small amounts of data
              related to your visit on your computer hard drive. Our cookies
              consist of an alphanumeric identifier that allows us to track the
              pages you've visited on our Site. We use cookies and other
              technologies, including digital image files known as web beacons
              (also referred to as pixel tags or clear GIFs), that allow us to
              track user activity and to provide better user experience. For
              example, our servers automatically collect usage information about
              how you and other visitors interact with our Site, such as the
              number of visitors to our Site and to pages within our Site, the
              frequency with which you and other visitors access individual
              pages within the Site, the time you and other visitors spend on
              the Site and on pages within the Site, and other similar
              information ("Usage Data"). We aggregate this Usage Data to
              understand overall visitor traffic patterns. We use this
              information to improve our Site by making it more responsive to
              the needs of our users. We also use cookies to deliver content
              specific to your interests, to avoid showing you the same ad or
              other message repeatedly, and to allow you to store your username
              and password so you don't have to re-enter them every time your
              visit our Site. Our cookies do not collect or contain your Contact
              and personal Information. They do enable us to relate information
              that you have voluntarily provided to us (such as information you
              provide when you respond to questionnaires or offers or request
              information on products or services) with your Contact
              Information. Welcome Cure does not combine the Usage Data we
              collect with your Contact Information. Usage Data enables us to
              figure out how often users access parts of the Sites so that we
              can make the Site appealing to as many users as possible, and
              improve the content. We may share this aggregate information with
              advertisers and other third parties, but it does not indicate the
              identity of individual users. Our advertisers and third parties
              that provide advertising, products and/or services through our
              site may also assign their own cookies, or place their own web
              beacons, a process we don't control. The help function on most web
              browsers contains information on how to set your browser to notify
              you before accepting a cookie or to disable cookies entirely.
              However, if you don't accept cookies, you won't be able to take
              advantage of some of our Sites' most attractive features. Many
              companies offer programs that help you to visit websites
              anonymously. While Welcome Cure will not be able to provide you
              with a personalized experience if we cannot recognize you, we want
              you to be aware that these programs are available. You may also
              note that the above in no way violate the right to privacy of any
              person and are within the reasonable permitted parameters.
            </p>
          </div>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
            3. Data Collected in Connection with Ad Serving and Targeting:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
            We use third party service providers, including Google, Inc., to
            serve the advertising displayed on our Site. We do not share your
            Contact Information with these third parties.
          </p>
          <div className="text-[14px] sm:text-[16px] text-justify">
            <p className=" pb-5">
              Individual advertisers may ask you to provide them information,
              including Contact Information; however, our advertisers don't
              provide us any information you elect to provide them. Such
              information is not subject to this privacy policy. Google uses a
              DoubleClick DART cookie on this Site and other websites in order
              to collect anonymous Usage Data that enables it to serve ads to
              you based on your visit to this Site and other sites on the
              Internet. You may opt-out of the use of the DART cookie through
              the Google ad and content network privacy policy at
              <a
                href="https://policies.google.com/technologies/ads"
                className="text-blue-500 hover:underline"
              >
                {" "}
                http://www.google.com/privacy_ads.html.
              </a>{" "}
              Welcome Cure may also work with third party advertising networks,
              such as Microsoft, Atlas and Akamai that target and serve some of
              the advertisements, promotions, and other marketing messages you
              see on the pages of our Site and other websites. Anonymous Usage
              Data is collected from our website and other websites so that
              these networks can use such information, sometimes in conjunction
              with similar anonymous Usage Data gathered through other websites,
              to deliver advertisements tailored to match the perceived
              interests of consumers. Usage Data regarding users of this Site
              may be used to serve targeted advertisements on this Site and
              other websites. This Usage Data may include the pages that you
              have visited on the Site and your use of the Site, but it does not
              identify you personally and does not include your Contact
              Information, but it may include the IP address of your computer.
              These third-party networks may also use cookies and/or web beacons
              to collect anonymous information directly from our users. To learn
              more about behavioural targeting of advertising and your opt-out
              options, generally, and to opt-out of the Atlas and/or Akamai
              advertising network click here: <a
                href="https://thenai.org/consumer/opt_out.asp"
                className="text-blue-500 hover:underline"
              >
                 http://networkadvertising.org/consumer/opt_out.asp
              </a>
              . You may opt out of the Microsoft advertising network by clicking
              here
              <a
                href="https://privacy.microsoft.com/en-us/fullnotice.mspx#EIG"
                className="text-blue-500 hover:underline"
              >
                http://privacy.microsoft.com/en-us/fullnotice.mspx#EIG
              </a>{" "}
              and following the instructions. We may also use Usage Data
              gathered on this Site and other websites to target and serve
              advertisements, promotions, and other marketing messages on this
              Site and other websites, both within and outside the Network.
            </p>
          </div>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          4. Use of Your Contact Information and Other Information by Other Websites in the Network:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          Welcome Cure and its subsidiaries may use your Contact Information and other information that is collected through this Site, may combine such information with other information gathered through other websites in the Network, and may use such combined information for any purpose for which this Site may use information gathered on this Site under this Privacy Policy. Welcome Cure and its subsidiaries may also use this information to contact you in the future to tell you about products or services we believe may be of interest to you. If you'd prefer not to receive such communications in the future.
          </p>
         
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          5. Our Disclosure of Your Contact Information and Other Information:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          Welcome Cure is not in the business of selling to third parties, or allowing third parties to rent, your Contact Information, although if you elect to receive third party offers and materials when you subscribe to a newsletter or register with the Site, we may use the Contact Information you provide or that we obtain from a third party to provide such materials by e-mail or direct mail. You may opt-out of having your Contact Information used in this way by un-checking the box in the registration or newsletter subscription page or by emailing us at askme@welcomecure.com. We consider this information to be a vital part of our relationship with you and will not disclose your Contact Information to a third party except as set forth in this Privacy Policy. There are, however, certain circumstances in which we may share your Contact Information (and other information that we have associated with your Contact Information) with certain third parties without further notice to you, as set forth below:
          </p>
          <div className="text-[14px] sm:text-[16px] text-justify">
            <p className=" pb-5">
              <span className="font-semibold">
              A. Business Transfers:
              </span>{" "}
               As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, amalgamation, reconstruction or reorganization, dissolution or similar event, Contact Information and other information that we have associated with Contact Information may be part of the transferred assets.
            </p>
            <p className=" pb-5">
              <span className="font-semibold">
              B. Vendors:
              </span>{" "}
               Welcome Cure, like many businesses, sometimes contracts with other companies to perform certain business-related functions. For example, we may use third party companies to provide email and direct mail services, database research and maintenance, payment processing, and other services. If you have registered with the Site, we may share your Contact Information and other information that we have associated with your Contact Information, as necessary or desirable for the third party to provide such services for us. Those third parties have contractually committed not to use your Contact Information (and the information we've associated with your Contact Information) for any other purpose.
            </p>
            <p className=" pb-5">
              <span className="font-semibold">
              C. Other Disclosures:
              </span>{" "}
               We may occasionally release information about our visitors when release is appropriate to comply with law or legal process, to enforce our Terms of Use or protect Welcome Cure against legal liability, or to protect the rights, property, health, or safety of visitors to our Site, our advertisers, vendors, the public or Welcome Cure. Except as set forth above, you will be notified before we share your Contact Information (and other information that we have associated with your Contact Information) with third parties, and we will not share such information with third parties without your consent, except as set forth above.
            </p>
          </div>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          6. Updating Our Records:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          If your Contact Information changes, if you wish to unsubscribe to a newsletter or to a notification or other service, you may correct, update, or delete our records or unsubscribe by logging onto the Site and clicking the "Profile" button in the upper righthand corner of any page. You may update your Profile by clicking on the "My Profile" tab and following the instructions and can unsubscribe to any Site service by clicking on the "My Setting" tab and following the instructions
          </p>
         
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          7. External Links:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          To provide users with greater value, we may provide links to various third party websites. However, we do not control the privacy practices or the content of the sites to which we link, nor can we guarantee the products or services you may obtain through such sites.
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          These links to third party websites are provided only for your convenience, and as such, you access them at your own risk. The site of its owners shall in no way be responsible or liable for any loss or damage which you shall incur in dealing with third party websites so linked to our site. That said, we are always seeking to improve our Site and would appreciate any comments you might have on any website to which we link. Please email us at <a href="mailto:askme@welcomecure.com" className="text-blue-500 hover:underline">askme@welcomecure.com.</a>
          </p>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          8. Public Postings:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          Please think carefully before disclosing Contact Information or any personal information in any of these public areas. Such information is not private; Welcome Cure is free to reproduce, use, disclose, and distribute such information to others without limitation or attribution. Any information you disclose may be used by third parties we don't control. For example, if you disclose your Contact Information in any of these public areas, it may be used by third parties to send you unsolicited advertisements or other messages. We are not responsible or liable in any manner whatsoever for any information you choose to submit/ post in these forums.
          </p>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          9. Security:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          Information we gather on our website is stored within Welcome Cure-controlled databases (or databases hosted on our behalf) on servers maintained by us. However, no physical or electronic security system is impenetrable. We cannot guarantee the security of our servers or databases, nor can we guarantee/ warrant that information you supply won't be intercepted while being transmitted to us over the Internet. You do so at your own risk and Welcome Cure is in no way responsible/ liable for any loss or damage arising in this regard.
          </p>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          10. Third Party Personal Data:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          At times, we may ask you for Contact Information relating to third parties. For example, if you ask us to email content to a friend using our "Email This Page to a Friend" tool, we'll ask you for your friend's email address. Welcome Cure stores this information for the purpose of emailing the content you've asked us to send. Your friend may contact us at <a href="mailto:askme@welcomecure.com" className="text-blue-500 hover:underline">askme@welcomecure.com</a>. request that we remove this information from our database.
          </p>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          11. Other Terms and Conditions:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          By visiting this Site, you agree that your visit and any dispute over data collection or privacy are governed by this Privacy Policy and our terms and conditions as applicable and then in force. All content provided on this site or any other website in our network is general in nature and is provided for informational purposes only.
          </p>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          12. Contacting the Site:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          If you have any questions about this Privacy Policy, the practices of this Site or your interaction with the Site ,please contact us by clicking on the "<a href="https://www.welcomecure.com/contact-us" className="text-blue-500 hover:underline">Contact Us</a>" link at the bottom of the page.
          </p>
        </div>

        <div className="container mx-auto   px-4">
          <p className="text-[18px] underline font-semibold">
          13. Changes to this Privacy Policy:
          </p>
          <p className="text-[14px] sm:text-[16px] pb-5 text-justify">
          The web is an evolving medium. If we need to change our privacy policy at any point of time in the future, we'll post the changes to this Privacy Policy on this Site and update the Effective Date at the beginning of the policy to reflect the date of the amendment. By accessing this Site after we post such changes, you agree that your visit, and any dispute over privacy, is governed by this Privacy Policy, as modified by us from time to time, and our Terms of Use effective on the date of visit or dispute.
          </p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

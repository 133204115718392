import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
//import react icon
import { MdClose } from "react-icons/md";
// import react select
import Select from "react-select";
// import hr emp action file 
import {
  CreateHREmployee,
  fileLoader,
} from "../../../Redux/Action/UploadEmployeeAction";
// import family actionn file
import {
  AddFamilyMember,
  FamilyLoader,
} from "../../../Redux/Action/AddFamilyAction";

//******************************************************** */ hr new employee modal
export const HrEmpModal = ({ setUploadEmployeemodel }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);

  useEffect(() => {
    if (user) {
      setSelectedCorporate(newcorporate?.data?.corporateID);
    }
  }, [user]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [corporate, setCorporate] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");

  const Corporatename = useSelector(
    (state) => state?.corporateData?.CorporateOptionlist?.data
  );

  useEffect(() => {
    const con =
      Corporatename &&
      Corporatename?.map((c) => ({
        value: c.corporateID,
        label: c.corporateName,
      }));
    setOptionList(con);
  }, [Corporatename]);

  const handleSave = (e) => {
    e.preventDefault();
    const formData = {
      name,
      email,
      contact,
      corporateID: newcorporate?.data?.corporateID,
      HrId: newcorporate?.data?.HrId,
    };

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(fileLoader());
    dispatch(CreateHREmployee(formData))
      .then((response) => {
        if (response && response.data && response.data.success) {
          setContact("");
          setEmail("");
          setName("");
          setCorporate("");
          setUploadEmployeemodel(false);
        }
      })
      .catch((error) => {
        // console.error("API request failed:", error);
      });
  };

  const customStyles = {
    //to remove seprator
    indicatorSeparator: () => ({}),

    control: (base) => ({
      ...base,
      background: "#FFFCE1",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#FCDD00" : "#808080", // Red border when focused, blue when not focused
      "&:hover": {
        borderColor: "#FCDD00", // Red border when hovering
      },
      padding: 3,
      border: "0px solid black",
      fontSize: 14,

      outline: "none",
      borderRadius: "8px",
      boxShadow: "none !important",
      backgroundColor: "#FFFCE1",
    }),

    //remove dropdown
    dropdownIndicator: () => ({
      display: "none",
    }),
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-50 bg-opacity-50">
        <div className="w-[340px] sm:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className=" rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Employee</div>
            <button
              onClick={() => {
                setUploadEmployeemodel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSave}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <div>
                  <Select
                    className=""
                    options={optionList}
                    value={optionList?.filter(
                      (option) => option.value === selectedCorporate
                    )}
                    placeholder="Select Corporate"
                    isSearchable={false}
                    isDisabled
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Employee Name<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="text"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Employee Name"
                  id="name"
                  name="name"
                  value={name}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    ))
                  }
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const trimmedValue = enteredValue.trim();
                    if (trimmedValue !== "" || enteredValue === "") {
                      setName(enteredValue);
                    }
                  }}
                  required
                  autoComplete="off"
                  maxLength={50}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Email Id<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="email"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Employee Email Id"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Contact No<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="tel"
                  className="py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                  placeholder="Enter Employee Contact No"
                  id="contact"
                  name="contact"
                  value={contact}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                  }
                  onChange={(e) => setContact(e.target.value)}
                  required
                  minLength={0}
                  // maxLength={10}
                  autoComplete="off"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] w-[100px] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

//*************************************************** */ hr employee family member modal
export const EmpFamilyModal = ({ setAddFamModel }) => {
  const dispatch = useDispatch();
  // get token from local storage
  const authToken = localStorage.getItem("Admin");
  const corporateID = JSON.parse(authToken)?.data?.corporateID;
  const employeeID = useSelector(
    (state) => state?.AddFamily?.EmpDetail?.data?._id
  );

  const [relationOption, setRelationOption] = useState([]);
  const [relationID, setrelationID] = useState(null);
  const [bloodOption, setBloodOption] = useState([]);
  const [bloodID, setbloodID] = useState(null);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // for relation drropdown
  const relation = useSelector((state) => state?.AddFamily?.RelationList?.data);

  useEffect(() => {
    const Relation =
      relation &&
      relation?.map((r) => ({
        value: r._id,
        label: r.relation,
      }));
    setRelationOption(Relation);
  }, [relation]);
  function SelectedRelation(selectedOption) {
    setrelationID(selectedOption);
  }

  const bloodgroup = useSelector((state) => state?.AddFamily?.BloodList?.data);

  useEffect(() => {
    const BloodGroup =
      bloodgroup &&
      bloodgroup?.map((b) => ({
        value: b._id,
        label: b.bloodgroup,
      }));
    setBloodOption(BloodGroup);
  }, [bloodgroup]);
  function SelectedBlood(selectedOption) {
    setbloodID(selectedOption);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (age === "" || age <= 0 || age > 100) {
      setErrorMessage("Please enter a valid age between 1 and 100.");
      return;
    }

    // Validate contact number
    if (contact === "" || contact.length !== 10) {
      setErrorMessage("Please enter a valid 10-digit contact number.");
      return;
    }

    const relation = relationID ? relationID.label : "";
    const bloodgroup = bloodID ? bloodID.label : "";
    const formData = {
      relation,
      name,
      age,
      contact,
      email,
      bloodgroup,
      employeeID,
      corporateID,
    };
    dispatch(AddFamilyMember(formData));
    dispatch(FamilyLoader());
  };

  const customStyles = {
    //to remove seprator
    indicatorSeparator: () => ({}),

    control: (base) => ({
      ...base,
      background: "#FFFCE1",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#FCDD00" : "#808080",
      "&:hover": {
        borderColor: "#FCDD00",
      },
      padding: 3,
      border: "0px solid black",
      fontSize: 14,

      outline: "none",
      borderRadius: "8px",
      boxShadow: "none !important",
      backgroundColor: "#FFFCE1",
    }),
    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: "#1A1A1A",
      backgroundColor: state.isSelected ? "#FCDD00" : "white",
      ":hover": {
        backgroundColor: "#FFFCE1",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px", // Set the desired max height
      overflow: "Y", // Add a vertical scrollbar if needed
    }),
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
        <div className="bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className=" rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Family Member</div>
            <button
              onClick={() => {
                setAddFamModel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1 w-[300px]">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Select Relation<span className="text-[#fc0000]">*</span> :
                  </span>
                  <div>
                    <Select
                      options={relationOption}
                      placeholder="Select Relation"
                      value={relationID}
                      onChange={SelectedRelation}
                      isSearchable={true}
                      styles={customStyles}
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1 w-[300px]">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Select Blood Group :
                  </span>
                  <div>
                    <Select
                      options={bloodOption}
                      placeholder="Select Blood Group"
                      value={bloodID}
                      onChange={SelectedBlood}
                      isSearchable={true}
                      styles={customStyles}
                      // required
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Name"
                    value={name}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /[^a-zA-Z0-9\s]/g,
                        ""
                      ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Age<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="w-[300px] py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                    type="number"
                    placeholder="Enter Age"
                    value={age}
                    onChange={(e) => {
                      setAge(e.target.value.slice(0, 3));
                      setErrorMessage("");
                    }}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                    required
                    autoComplete="off"
                    min={0}
                    max={100}
                    step={0}
                  />
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Email Id<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="email"
                    placeholder="Enter Email Id"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Contact No<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="w-[300px] py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Contact No"
                    id="contact"
                    name="contact"
                    value={contact}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => {
                      setContact(e.target.value);
                      setErrorMessage("");
                    }}
                    required
                    autoComplete="off"
                    min={0}
                    // maxLength={10}
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="text-red-600">{errorMessage}</div>
              )}
              <div className="flex justify-end">
                <button
                  onSubmit={handleSubmit}
                  type="submit"
                  className="w-[100px] bg-[#FCDD00] text-[#1A1A1A]  px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

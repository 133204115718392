import React from "react";
import Router from "./Router";
import "../src/component/Admin/axios-interceptors" 

function App() {

    return (
      <div>
        <Router/>
      </div>
    );
  }
  export default App;
export const actionTypes = {
    SET_LOGIN: "SET_LOGIN",
    LOADER: "LOADER",
    FORGET_PASSWORD: "FORGET_PASSWORD",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
  
  };
  
  const INITIAL_STATE = {
    login: [],
    loader: false,
    forgetpassword: [],
    changepassword: [],
    resetpassword: [],
  };
  
  const LoginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.LOADER:
        return {
          ...state,
          loader: action.loader
        }
      case actionTypes.SET_LOGIN:
        return {
          ...state,
          login: action.login,
          loader: action.loader
        };
        case actionTypes.FORGET_PASSWORD:
        return {
          ...state,
          forgetpassword: action.forgetpassword,
          loader: action.loader
        };
        case actionTypes.CHANGE_PASSWORD:
        return {
          ...state,
          changepassword: action.changepassword,
          loader: action.loader
        };
        case actionTypes.RESET_PASSWORD:
        return {
          ...state,
          resetpassword: action.resetpassword,
          loader: action.loader
        };
      default:
        return state;
    }
  };
  
  export default LoginReducer;
  
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import ReactPaginate from "react-paginate";
import "../../../src/GlobalCssStyle.css";
import AdminLayout from "../../component/layout/AdminLayout/AdminLayout";
//import action file
import {
  CorpLoginDetails,
  CorpMailStatus,
  CorporateLoader,
  GetCorporateDetail,
  GetCorporatelist,
  UpdateCorporate,
} from "../../Redux/Action/CorporateAction";
//import icone
import { RiSearch2Line, RiAddLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import LimitArrow from "../../images/LimitArrow.svg"
//import tostify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import loader
import Loader from "../../component/Admin/Loader";
//import modal
import { CorpEditModel } from "../../component/Modal/AdminModal/EditModel";
import { CroporateCreateModal, LoginDetailModal } from "../../component/Modal/AdminModal/CreateModal";

const Corporate = () => {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [corporatemodel, setCorporatemodel] = useState(false);
  const [selectpage, setselectpage] = useState("");
  const [checkvalue, setcheckvalue] = useState("");
  const [loginDetailModal, setLoginDetailModal] = useState(false)

  const itemsPerPageOptions = [
    { value: 10, label: 10, id: 1 },
    { value: 25, label: 25, id: 2 },
    { value: 50, label: 50, id: 3 },
  ];

  useEffect(() => {
    // searchParams.delete("search");
    // searchParams.delete("corporateID");
    setSearchParams(searchParams);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    // delete object.search;
    // delete object.corporateID;
    dispatch(GetCorporatelist({ ...object }));
  }, []);

  const newdata = useSelector((state) => state?.corporateData?.NewCorporate);

  useEffect(() => {
    if (newdata.code === 200) {
      setCorporatemodel(false);
    }
  }, [newdata]);

  const [page, setpage] = useState("");
  const [Search, setSearch] = useState("");

  useEffect(() => {
    setpage(itemsPerPageOptions[0]?.value);
  }, []);

  // for select page data
  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      setselectpage(0)
      history({
        pathname: "/admin/admin_user/corporate",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value,page:1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/corporate",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(GetCorporatelist({ ...object, limit: item.value,page:1 }));
  };

  // for pagination
  const handleFirstPage = () => {
    setselectpage(0);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/corporate",
        search: "?" + new URLSearchParams({ ...object, page: 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/corporate",
        search: `?page=1`,
      });
    }
    dispatch(GetCorporatelist({ ...object, page: 1 }));
  };
  const handleLastPage = () => {
    const lastPage = pageCount - 1;
    setselectpage(lastPage);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/corporate",
        search:
          "?" +
          new URLSearchParams({ ...object, page: lastPage + 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/corporate",
        search: `?page=${lastPage + 1}`,
      });
    }
    dispatch(GetCorporatelist({ ...object, page: lastPage + 1 }));
  };
  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/admin/admin_user/corporate",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/corporate",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(GetCorporatelist({ ...object, page: selected.selected + 1 }));
  };

  const [Itemperpage, setItemperpage] = useState("");

  const newcorporateData = useSelector(
    (state) => state?.corporateData?.Corporatelist?.data
  );

  const pageCount = Math.ceil(Number(newcorporateData?.count) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [newcorporateData]);

  //  for search filter
  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/corporate",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/corporate",
        search: `?search=${Search}`,
      });
    }
    dispatch(GetCorporatelist({ ...object, search: Search }));
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("search");
    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
      const sds = location.search.slice(1).split("&");
      let object = {};
      if (location.search.includes("?")) {
        sds.map((item) => {
          let newItem = item.split("=");
          object[newItem[0]] = newItem[1];
        });
      }
      delete object.search;
      dispatch(GetCorporatelist({ ...object }));
    }
  };

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if (newlimit?.length > 0) {
      setpage(newlimit);
    }
  }, []);

  useEffect(() => {
    const newpage = searchParams.get("page");
    if (newpage?.length > 0 && newpage !== null) {
      setselectpage(newpage - 1);
    }
  }, []);

  // for loader
  const loader = useSelector((state) => state?.corporateData?.corporateloader);

  //for edit cor detail
  const [editCorpModel, setEditCorpModel] = useState(false);
  const [EditId, setEditId] = useState("");

  const handleEdit = (_id) => {
    dispatch(GetCorporateDetail(_id));
    setEditCorpModel(true);
    setEditId(_id);
  };

  const handelMail = (_id) => {
    dispatch(CorpMailStatus(_id));
    dispatch(CorporateLoader());
  };

  const handelLoginDetail = (_id) => {
    dispatch(CorpLoginDetails(_id));
    dispatch(CorporateLoader());
    setLoginDetailModal(true)
  };

  //colse edit pop
  const EidtCorpo = useSelector(
    (state) => state?.corporateData?.editcorpdetail
  );
  useEffect(() => {
    if (EidtCorpo.code === 200) {
      setEditCorpModel(false);
    }
  }, [EidtCorpo]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //for pagination
  const reactPaginate = {
    previousLabel: "<",
    nextLabel: ">",
    breakLabel: "",
    pageCount: Itemperpage,
    onPageChange: handlePageChange,
    containerClassName: "pagination",
    previousLinkClassName: "pagination__link",
    nextLinkClassName: "pagination__link",
    disabledClassName: "pagination__link--disabled",
    activeClassName: "pagination__link--active",
    forcePage: selectpage,
  };
  return (
    <>
      <AdminLayout>
        {/* for Loader  */}
        {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}

        {/* for create corporate */}
        {corporatemodel && <CroporateCreateModal {...{ setCorporatemodel }} />}

        {/* for edit Corporate Detail  */}
        {editCorpModel && <CorpEditModel {...{ setEditCorpModel, EditId, selectpage }} />}

        {/* for login Detail  */}
        {loginDetailModal && <LoginDetailModal {...{setLoginDetailModal}} />}

        <div className="flex flex-col h-full px-5 py-[20px] xl:py-[30px] 2xl:justify-between">
          {/* --------------------------------------------Search Bar ---------------------------------------------------------- */}
          <div className="bg-white p-4 rounded-lg  w-full mb-5">
            <div className=" md:flex gap-3 md:gap-0 justify-between items-center">
              <div className="relative mt-3 md:mt-0">
                <div className="absolute top-[8px] left-[20px] text-gray-400 text-2xl">
                  <RiSearch2Line className="w-6 h-6" />
                </div>

                <form
                  onSubmit={(e) => {
                    e?.preventDefault();
                    if (Search.trim() !== "") {
                      submitsearch(e);
                    }
                  }}
                >
                  <input
                    className="h-full bg-[#FFFCE1] border rounded-[8px] py-2 pl-[50px] w-[250px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium outline-[#FCDD00]"
                    placeholder="Search By Corporate Name"
                    value={Search}
                    onChange={(e) => {
                      const inputValue = e.target.value.trimStart();
                      setSearch(inputValue);
                      if (inputValue.trim() === "") {
                        removeQueryParams();
                      }
                    }}
                  />
                </form>
              </div>
              <div className="mt-3 md:mt-0  w-[200px] xl:w-[300px] flex md:justify-end">
                <button
                  onClick={() => setCorporatemodel(true)}
                  className="bg-[#FCDD00] text-[#1A1A1A] px-3 py-[6px] rounded-lg font-Poppins text-lg flex items-center gap-1 cursor-pointer  hover:scale-105"
                >
                  <span className="text-2xl">
                    <RiAddLine />
                  </span>
                  Add
                </button>
              </div>
            </div>
          </div>
          {/* --------------------------------------------Content Table------------------------------------------------------- */}
          <div className="w-full h-[520px] xl:h-[610px]   overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
            {newcorporateData?.corporateData?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <p className="font-Roboto text-xl ">
                  No Corporate Details Available.
                </p>
              </div>
            ) : (
              <table className="corporate-table w-[100%] text-left   font-Poppins">
                <thead>
                  <tr className="z-20">
                    <th>
                      <div className="pl-[40px] py-3 xl:py-5 min-w-[200px]">
                        Corporate Name
                      </div>
                    </th>
                    <th>
                      <div className="min-w-[160px]">Name</div>
                    </th>
                    <th>
                      <div className="min-w-[280px]">Email Id</div>
                    </th>
                    <th>
                      <div className="min-w-[160px]">Contact No.</div>
                    </th>
                    <th>
                      <div className="min-w-[130px]">Status</div>
                    </th>
                    <th>
                      <div className="min-w-[120px]">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newcorporateData?.corporateData?.length > 0 &&
                    newcorporateData?.corporateData?.map((user, index) => (
                      <tr key={index}>
                        {/* for Corporate Name  */}
                        <td className="pl-[40px] py-2 xl:py-[11.5px] h-[46px]">
                          <div className="flex gap-2">
                            <div className="w-[30px] h-[30px] bg-gray-500 hover:z-40 object-contain">
                              <img
                                src={`${newcorporateData?.storageurl}${user.logo}`}
                                className="w-[30px] h-[30px] bg-gray-500 logo-img hover:z-40 "
                              />
                            </div>
                            <span className="text-sm text-left capitalize">
                              {user?.corporateName?.length > 25 ? (
                                <div className="capitalize tooltip">
                                  {`${user?.corporateName?.substring(
                                    0,
                                    25
                                  )}...`}
                                  <span className="tooltiptext">
                                    {user.corporateName}
                                  </span>
                                </div>
                              ) : (
                                <div className="capitalize">
                                  {user.corporateName}
                                </div>
                              )}
                            </span>
                          </div>
                        </td>

                        {/* for name  */}
                        {user?.name?.length > 35 ? (
                          <td>
                            <div className="tooltip capitalize">
                              {`${user?.name?.substring(0, 35)}...`}
                              <span className="tooltiptext">{user.name}</span>
                            </div>
                          </td>
                        ) : (
                          <td className="capitalize">
                            <div>{user.name}</div>
                          </td>
                        )}

                        {/* for email  */}
                        {user?.email?.length > 35 ? (
                          <td>
                            <div className="tooltip ">
                              {`${user?.email?.substring(0, 35)}...`}
                              <span className="tooltiptext">{user.email}</span>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div>{user.email}</div>
                          </td>
                        )}

                        {/* for phone no  */}
                        <td>{user.contactno}</td>

                        {/* for status  */}
                        <td>
                          <div className=" flex">
                            <div className=" flex flex-col items-center justify-center">
                              {/* Code block starts */}
                              <div className="cursor-pointer rounded-full bg-gray-200 relative shadow-sm">
                                <input
                                  type="checkbox"
                                  name="toggle"
                                  id={user?._id}
                                  defaultChecked={user.status == true}
                                  role="switch"
                                  onChange={(e) => {
                                    setcheckvalue(e.target.checked);

                                    const sds = location.search
                                      .slice(1)
                                      .split("&");
                                    let object = {};
                                    if (location.search.includes("?")) {
                                      sds.map((item) => {
                                        let newItem = item.split("=");
                                        object[newItem[0]] = newItem[1];
                                      });
                                    }
                                    dispatch(
                                      UpdateCorporate(
                                        user?._id,
                                        e.target.checked,
                                        { ...object,search: Search }
                                      )
                                    );
                                  }}
                                  value={checkvalue}
                                  className={`${
                                    user.status == false ? "left-0" : "right-0"
                                  } focus:outline-none checkbox w-[22px] h-[22px] mx-[3px] rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto`}
                                />
                                <label
                                  htmlFor={user?._id}
                                  className={`${
                                    user.status == true
                                      ? "bg-[#fcdd00]"
                                      : "bg-[#dfdfdf]"
                                  }  block w-[50px] r-2 h-[26px] overflow-hidden rounded-full  cursor-pointer`}
                                />
                              </div>
                            </div>
                            {/* Code block ends */}
                          </div>
                        </td>

                        {/* for action  */}
                        <td>
                          <div className=" mx-auto flex gap-3">
                            {user?.status === true ? (
                              <span className="text-xl">
                                <BiEdit
                                  className="cursor-pointer"
                                  onClick={() => handleEdit(user?._id)}
                                />
                              </span>
                            ) : (
                              <div className="tooltip">
                                <span className="text-xl">
                                  <BiEdit className="cursor-pointer" />
                                </span>
                                <div className="tooltiptext">
                                  This corporate status has been deactivated.
                                </div>
                              </div>
                            )}

                            <div
                            // className={`capitalize ${
                            //   user?.action ? "text-[#FCDD00]" : ""
                            // }`}
                            >
                              {user?.status === true ? (
                                <span className="text-xl">
                                  <AiOutlineMail
                                    className="cursor-pointer"
                                    onClick={() => handelMail(user?._id)}
                                  />
                                </span>
                              ) : (
                                <div className="tooltip">
                                  <span className="text-xl">
                                    <AiOutlineMail className="cursor-pointer" />
                                  </span>
                                  <span className="tooltiptext">
                                    This corporate status has been deactivated.
                                  </span>
                                </div>
                              )}
                            </div>
                            <div  className="text-xl">
                              <BsEye onClick={() => handelLoginDetail(user?._id)} className="cursor-pointer"/>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
          {/* --------------------------------------------Footer---------------------------------------------------------- */}
          <div className="footer-content mt-5">
            <div className=" md:flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <p className="capitalize">View</p>
                <div className="">
                  <Menu as="div" className="relative text-left">
                    <div>
                      <Menu.Button className="flex px-[10px] ml-[24px] min-h-[37px] bg-white border-2 border-[#F2F2F2] min-w-[60px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                        <p className="text-[#47505F] font-medium font-[poppins]">
                          {page}
                        </p>
                        <img src={LimitArrow} alt="LimitArrow" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[70%] rounded-[12px] bg-white shadoe-sm focus:outline-none border  mb-2">
                        {itemsPerPageOptions?.map((e) => (
                          <div key={e?.id}>
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={classNames(
                                    active ? "bg-[#FFFCE1] text-[#1A1A1A]" : "",
                                    "block px-2 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={() => onselectedpage(e)}
                                >
                                  {e?.value}
                                </p>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              {/* --------------------------------------------Footer Pagination---------------------------------------------------------- */}

              <div className="flex items-center mt-3 md:mt-0">
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleFirstPage}
                >
                  &lt;&lt;
                </button>
                <div className="hidden md:block">
                  <ReactPaginate
                    {...reactPaginate}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                  />
                </div>
                <div className="md:hidden">
                  <ReactPaginate
                    {...reactPaginate}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={2}
                  />
                </div>
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleLastPage}
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </AdminLayout>
    </>
  );
};

export default Corporate;
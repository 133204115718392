import React, { useState } from "react";
import DoctorLayout from "../../component/layout/DoctorLayout/DoctorLayout";
import AgoraUIKit from "agora-react-uikit";
import "agora-react-uikit/dist/index.css";
import VC from "../../images/Doctor/VC.svg";

const Consultation = () => {
  const [videoCall, setVideoCall] = useState(false);
  const rtcProps = {
    appId: "0ac40c5718c04f1daba0be966b81a099",
    channel: "test",
    token:
      "007eJxTYJivVLB5uiqHTtqDTWWzAybbcdhn+/5+LNn3qOU0V6Lf8aUKDAaJySYGyabmhhbJBiZphimJSYkGSamWZmZJFoaJBpaW19q2pDYEMjI4XlNgYmSAQBCfhaEktbiEgQEAEKEfEw==",
    layout: "floating",
  };
  const callbacks = {
    EndCall: () => setVideoCall(false),
  };
  return (
    <DoctorLayout>
      <div className="flex flex-col h-full px-5 py-[20px] xl:py-[30px] 2xl:justify-between">
        {videoCall ? (
          <div className="main flex w-[800px] h-[95%] 2xl:h-[75%] justify-center m-auto relative">
            <AgoraUIKit
              class="main2"
              rtcProps={rtcProps}
              callbacks={callbacks}
            />
          </div>
        ) : (
          <div className="m-auto w-[800px] h-[95%] 2xl:h-[75%] shadow-md rounded-xl overflow-hidden bg-slate-50">
            <div className="w-full h-full flex flex-col justify-between items-center">
              <div className="flex justify-center py-8 basis-[90%]">
                <img src={VC} alt="vc icone" />
              </div>
              <div className="w-full basis-[10%]">
                <button
                  onClick={() => setVideoCall(true)}
                  className="bg-[#FCDD00] px-4 py-4 text-center w-full"
                >
                  Start Call
                </button>
              </div>
            </div>

            {/* */}
          </div>
        )}
      </div>
    </DoctorLayout>
  );
};

export default Consultation;

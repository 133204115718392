const actionTypes = {
  ADD_DOCTOR: "ADD_DOCTOR",
  DOCTOR_LIST: "DOCTOR_LIST",
  DOCTOR_DETAIL: "DOCTOR_DETAI",
  EDIT_DOCTOR_DETAIL: "EDIT_DOCTOR_DETAIL",
  DOC_TIME_SLOT: "DOC_TIME_SLOT",
  LOADER: "LOADER",
  DOC_EMP_LIST: "DOC_EMP_LIST",
  DOC_EMP_REPORT_DETAIL: " DOC_EMP_REPORT_DETAIL",
  DOC_EMP_FAMILY_DETAIL: " DOC_EMP_FAMILY_DETAIL",
  RESET_DATA: "RESET_DATA",
};

const initialState = {
  addDoctor: [],
  doctorList: [],
  doctorDetail: [],
  editdoctordetail: [],
  doctorloader: false,
  doctimeslote: [],
  docemplist: [],
  docempreport: [],
  docempfamily: [],
  resetdata: [],
};

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADER:
      return {
        ...state,
        doctorloader: action.doctorloader,
      };
    case actionTypes.ADD_DOCTOR:
      return {
        ...state,
        addDoctor: action.addDoctor,
        doctorloader: action.doctorloader,
      };
    case actionTypes.DOCTOR_LIST:
      return {
        ...state,
        doctorList: action.doctorList,
      };
    case actionTypes.DOCTOR_DETAIL:
      return {
        ...state,
        doctorDetail: action.doctorDetail,
      };
    case actionTypes.EDIT_DOCTOR_DETAIL:
      return {
        ...state,
        editdoctordetail: action.editdoctordetail,
        doctorloader: action.doctorloader,
      };
    case actionTypes.DOC_TIME_SLOT:
      return {
        ...state,
        doctimeslote: action.doctimeslote,
      };
    case actionTypes.DOC_EMP_LIST:
      return {
        ...state,
        docemplist: action.docemplist,
      };
    case actionTypes.DOC_EMP_REPORT_DETAIL:
      return {
        ...state,
        docempreport: action.docempreport,
      };
    case actionTypes.DOC_EMP_FAMILY_DETAIL:
      return {
        ...state,
        docempfamily: action.docempfamily,
      };
    case actionTypes.RESET_DATA:
      return {
        ...state,
        docempfamily: action.docempfamily,
      };
    default:
      return state;
  }
};

export default doctorReducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Select from "react-select";
//import react icone
import { MdClose } from "react-icons/md";
//import action file for corporate
import {
  CorporateLoader,
  CreateCorporate,
} from "../../../Redux/Action/CorporateAction";
//import action file for health tips
import {
  GetImageList,
  imageLoader,
  uploadImage,
} from "../../../Redux/Action/HealthTipsAction";
//import action file for hr
import { CreateHr, HRLoader } from "../../../Redux/Action/HrAction";
//import action file for lab test
import {
  GetLabList,
  labLoader,
  uploadLabTest,
} from "../../../Redux/Action/LabTestAction";
//import action file for doctor
import {
  CreateDoctor,
  DoctorLoader,
  GetDoctorList,
} from "../../../Redux/Action/DoctorAction";
//import action file for employe
import {
  CreateEmployee,
  fileLoader,
} from "../../../Redux/Action/UploadEmployeeAction";
import upload from "../../../images/Upload.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//****************************************** Corporate Create Modal *********************************
export const CroporateCreateModal = ({ setCorporatemodel }) => {
  const dispatch = useDispatch();
  // get token from local storage
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const [corporateName, setCorporateName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactno] = useState("");
  const [uploadimages, setuploadimages] = useState([]);
  const [uploadimagesURLs, setuploadimagesURLs] = useState([]);
  const [ImageValidationError, setImageValidationError] = useState("");
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  const [hContact , setHContact] = useState("");
  const [aContact , setAContact] = useState("");
  let errorsObj = { uploadimage: "", image: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (uploadimages?.length < 1) return;
    const newImageURLs = [];
    uploadimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setuploadimagesURLs(newImageURLs);
  }, [uploadimages]);

  const onuploadimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    const file = e?.target?.files[0];
    setImageValidationError("");
    if (file) {
      const fileType = file.type;
      if (fileType === "image/gif") {
        errorObj.uploadimage =
          "Please upload an image with the .jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.webp extension";
        setErrors(errorObj);
        resetImageState();
        return;
      }
      if (file.size > 5000000) {
        errorObj.uploadimage = "The file should not be greater than 5 MB.";
        setErrors(errorObj);
        resetImageState();
        return;
      }
    }

    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        errorObj.uploadimage = "";
        setErrors(errorObj);
        setuploadimages([file]);
        setImageValidationError("");
      };

      image.onerror = () => {
        errorObj.uploadimage = "Invalid image file.";
        setErrors(errorObj);
        resetImageState();
        error = true;
      };
    }
    if (error) {
      return;
    }
  };
  const resetImageState = () => {
    setuploadimages([]);
    setuploadimagesURLs([]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");
    if (uploadimages.length === 0) {
      setImageValidationError("Please upload the profile logo.");
      return;
    }
    if (startDate && !endDate) {
      setError("Please select an End date.");
      return;
    }
    if (!startDate && endDate) {
      setError("Please select an Start date.");
      return;
    }
    const formattedStartDate = Math.floor(new Date(startDate).getTime());
    const formattedEndDate = Math.floor(new Date(endDate).getTime());
    try {
      const formData = new FormData();
      formData.append("logo", uploadimages[0]);
      formData.append("corporateName", corporateName);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("contactno", contactno);
      formData.append("startdate", formattedStartDate);
      formData.append("enddate", formattedEndDate);
      formData.append("primary", primary);
      formData.append("secondary", secondary);
      formData.append("homContact", hContact);
      formData.append("aloContact", aContact);
      dispatch(CreateCorporate(formData, token));
      dispatch(CorporateLoader());
    } catch (error) {
      // console.log(error);
    }
  };

  const handleStartDateChange = (date) => {
    const selectedStartDate = date;

    if (endDate && selectedStartDate > endDate) {
      setError("Start date should be less than the End date.");
      setStartDate("");
    } else {
      setStartDate(selectedStartDate);
      setError("");
    }
  };

  const handleEndDateChange = (date) => {
    const selectedEndDate = date;

    if (startDate && selectedEndDate < startDate) {
      setError("End date should be greater than the Start date.");
      setEndDate("");
    } else {
      setEndDate(selectedEndDate);
      setError("");
    }
  };

  const handleMember = (e) => {
    const value = e.target.value;
    setPrimary(value);
    setSecondary(value ? value * 4 : "");
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-30 bg-opacity-50">
        <div className="bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-5 md:px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Corporate</div>
            <button
              onClick={() => {
                setCorporatemodel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-5 md:p-3 xl:p-5 flex flex-col gap-3">
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Corporate Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00] input"
                    type="text"
                    placeholder="Enter Corporate Name"
                    id="corporateName"
                    name="corporateName"
                    value={corporateName}
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setCorporateName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Name"
                    id="name"
                    name="name"
                    value={name}
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Email Id<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="email"
                    placeholder="Enter Corporate Email Id"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Contact No<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[300px] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Corporate Contact No"
                    id="contact"
                    name="contact"
                    value={contactno}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => {
                      setContactno(e.target.value);
                    }}
                    required
                    autoComplete="off"
                    // maxLength={10}
                    minLength={0}
                  />
                </div>
              </div>

              <div className="md:flex gap-3">
                <div>
                  <div className="flex gap-3">
                    {/* start  */}
                    <div className="flex flex-col gap-1">
                      <span className="font-Poppins text-[15px] xl:text-base">
                        Start Date :
                      </span>
                      <DatePicker
                        className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] uppercase input-container outline-[#FCDD00] custom-datepicker"
                        selected={startDate}
                        value={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD-MM-YYYY"
                        id="datepicker"
                        showPopperArrow={false}
                        popperClassName="some-custom-class"
                        popperPlacement="top-start"
                        popperModifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [5, 10],
                            },
                          },
                          {
                            name: "preventOverflow",
                            options: {
                              rootBoundary: "viewport",
                              tether: false,
                              altAxis: true,
                            },
                          },
                        ]}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-Poppins text-[15px] xl:text-base">
                        End Date :
                      </span>
                      <DatePicker
                        className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] uppercase input-container outline-[#FCDD00]"
                        selected={endDate}
                        value={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD-MM-YYYY"
                        id="datepicker"
                        showPopperArrow={false}
                        popperClassName="some-custom-class"
                        popperPlacement="top-start"
                        popperModifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [5, 10],
                            },
                          },
                          {
                            name: "preventOverflow",
                            options: {
                              rootBoundary: "viewport",
                              tether: false,
                              altAxis: true,
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 mt-2">
                    <div className="flex flex-col gap-1">
                      <span className="font-Poppins text-[15px] xl:text-base">
                        Member Limit :
                      </span>
                      <div className="flex gap-3">
                        <input
                          type="number"
                          className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] outline-[#FCDD00]"
                          value={primary}
                          onChange={handleMember}
                          placeholder="Primary"
                          min={1}
                        />
                        <input
                          type="text"
                          className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] w-[143px] outline-[#FCDD00]"
                          value={secondary}
                          readOnly
                          placeholder="Secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Add Logo<span className="text-[#fc0000]">*</span> :
                  </span>
                  <label htmlFor="inputTagsf" className=" flex">
                    <div className="bg-[#fff] w-[100px] h-[100px] xl:h-[105px] xl:w-[105px] overflow-hidden border-dashed border-2 border-[#FCDD00] flex items-center justify-center cursor-pointer  rounded-[10px] mt-[6px]">
                      <input
                        id="inputTagsf"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        className="hidden"
                        onChange={onuploadimagechange}
                      />
                      {uploadimagesURLs && uploadimagesURLs[0] ? (
                        <div className="w-full h-full relative group p-1">
                          <img
                            src={uploadimagesURLs}
                            className="w-full h-full rounded-[6px]"
                            alt="new"
                          ></img>
                          <div className="">
                            <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                              <img src={upload} className="mx-auto" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex justify-center items-center h-full">
                          <div className=" text-center">
                            <img src={upload} className="mx-auto" />
                            <p className="text-[#FCDD00] text-sm font-medium font-poppins">
                              Upload Image
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </label>
                  {errors.uploadimage && (
                    <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                      {errors.uploadimage}
                    </div>
                  )}
                  {ImageValidationError && (
                    <p className="mt-2 text-[#FF0101] text-sm font-poppins">
                      {ImageValidationError}
                    </p>
                  )}
                </div>
              </div> 
               <div>
                  {error && (
                    <p className="mt-2 text-[#FF0101] text-sm font-poppins">
                      {error}
                    </p>
                  )}
                </div>
              <div className="md:flex justify-between md:items-end">
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Homeopathy Contact<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm md:w-[220px] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Homeopathy Contact"
                    value={hContact}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => setHContact(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1 mt-2 md:mt-0">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Alopathy Contact<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 text-sm rounded-lg  bg-[#FFFCE1] md:w-[220px] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Alopathy Contact"
                    id="contact"
                    name="contact"
                    value={aContact}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => {
                      setAContact(e.target.value);
                    }}
                    required
                    autoComplete="off"
                    minLength={0}
                  />
                </div>
              </div>
                <div className="mt-2 md: mt-0md:flex justify-end">
                  <button
                    onSubmit={handleSubmit}
                    type="submit"
                    className="bg-[#FCDD00] text-[#1A1A1A] w-[100px] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
//****************************************** Hr Create Modal *********************************
export const HrCreateModal = ({ setHrmodel }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactno] = useState("");
  const [modalOptionList, setModalOptionList] = useState([]);
  const [corporateID, setcorporateID] = useState(null);
  const [error, seterror] = useState("");
  function handleSelect(selectedOption) {
    setcorporateID(selectedOption);
  }

  const ActiveCorporateList = useSelector(
    (state) => state?.corporateData?.ActiveCorporateOptionList?.data
  );

  useEffect(() => {
    const Actcon =
      ActiveCorporateList &&
      ActiveCorporateList?.map((c) => ({
        value: c.corporateID,
        label: c.corporateName,
        color: c.status ? "black" : "#808080",
      }));
    setModalOptionList(Actcon);
  }, [ActiveCorporateList]);

  const handleSubmit = (e) => {
    const selectedCorporateValue = corporateID ? corporateID.value : "";

    e.preventDefault();
    const formData = {
      corporateID: selectedCorporateValue,
      name,
      email,
      contactno,
    };
    if (name.trim() === "") {
      seterror("Please enter a valid name.");
      return;
    }
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(CreateHr(formData, { ...object }));
    dispatch(HRLoader());
  };

  // for searchable-dropdwon css
  const customStyles = {
    //to remove seprator
    indicatorSeparator: () => ({}),

    control: (base) => ({
      ...base,
      background: "#FFFCE1",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#FCDD00" : "#808080", // Red border when focused, blue when not focused
      "&:hover": {
        borderColor: "#FCDD00", // Red border when hovering
      },
      padding: 3,
      border: "0px solid black",
      fontSize: 14,

      outline: "#FCDD00",
      borderRadius: "8px",
      boxShadow: "none !important",
      backgroundColor: "#FFFCE1",
    }),
    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: "#1A1A1A",
      backgroundColor: state.isSelected ? "#FCDD00" : "white",
      ":hover": {
        backgroundColor: "#FFFCE1",
      },
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px", // Set the desired max height
      overflow: "Y",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#FCDD00"
      }
    })
  };
  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-30 bg-opacity-50">
        <div className="w-[340px] sm:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Hr</div>
            <button
              onClick={() => {
                setHrmodel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Select Corporate<span className="text-[#fc0000]">*</span> :
                </span>
                <div>
                  <Select
                    options={modalOptionList}
                    placeholder="Select Corporate"
                    value={corporateID}
                    onChange={handleSelect}
                    isSearchable={true}
                    styles={customStyles}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  HR Name<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="text"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Hr Name"
                  id="name"
                  name="name"
                  value={name}
                  onInput={(e) =>
                  (e.target.value = e.target.value.replace(
                    /[^a-zA-Z0-9\s]/g,
                    ""
                  ))
                  }
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const trimmedValue = enteredValue.trim();
                    if (trimmedValue !== "" || enteredValue === "") {
                      setName(enteredValue);
                    }
                  }}
                  required
                  autoComplete="off"
                  maxLength={50}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Email Id<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="email"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Hr Email Id"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Contact No<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="tel"
                  className="py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                  placeholder="Enter Hr Contact No"
                  id="contact"
                  name="contact"
                  value={contactno}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                  }
                  onChange={(e) => setContactno(e.target.value)}
                  minLength={0}
                  // maxLength={10}
                  required
                  autoComplete="off"
                />
              </div>
              {error && <p className="text-[#fc0000]">{error}</p>}
              <div className="flex justify-end">
                <button
                  onSubmit={handleSubmit}
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] w-[100px] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
//****************************************** Employee Create Modal *********************************
export const EmployeeCreateModal = ({
  setUploadEmployeemodal,
  optionList,
  setError,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [corporateID, setcorporateID] = useState("");
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [error, seterror] = useState("");

  const [Corporate, SetCorporate] = useState("");
  const handleSave = (e) => {
    const selectedCorporateValue = Corporate ? Corporate.value : "";
    e.preventDefault();
    const formData = {
      corporateID: selectedCorporateValue,
      name,
      email,
      contact,
    };
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(fileLoader());
    dispatch(CreateEmployee(formData, { ...object }))
      .then((response) => {
        if (response && response.data && response.data.success) {
          setContact("");
          setEmail("");
          setName("");
          setSelectedCorporate("");
          SetCorporate("");
          setcorporateID("");
          setUploadEmployeemodal(false);
        }
      })
      .catch((error) => { });
  };

  function modelhandelSelect(selectedOption) {
      SetCorporate(selectedOption);
      setcorporateID(selectedOption);
      setError("");
  }

  // for searchable-dropdwon css
  const customstyles = {
    //to remove seprator
    indicatorSeparator: () => ({}),

    control: (base) => ({
      ...base,
      background: "#FFFCE1",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#FCDD00" : "#808080", // Red border when focused, blue when not focused
      "&:hover": {
        borderColor: "#FCDD00", // Red border when hovering
      },
      padding: 3,
      border: "0px solid black",
      fontSize: 14,

      outline: "none",
      borderRadius: "8px",
      boxShadow: "none !important",
      backgroundColor: "#FFFCE1",
    }),
    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: "#1A1A1A",
      backgroundColor: state.isSelected ? "#FCDD00" : "white",
      ":hover": {
        backgroundColor: "#FFFCE1",
      },
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px", // Set the desired max height
      overflow: "Y",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#FCDD00"
      }
    }),
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-30 bg-opacity-50">
        <div className=" w-[340px] sm:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className=" rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Employee</div>
            <button
              onClick={() => {
                setUploadEmployeemodal(false);
                setcorporateID("");
                SetCorporate("");
                setName("");
                setEmail("");
                setContact("");
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSave}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Select Corporate<span className="text-[#fc0000]">*</span>:
                </span>
                <div>
                  <Select
                    options={optionList}
                    placeholder="Select Corporate"
                    value={Corporate}
                    onChange={modelhandelSelect}
                    isSearchable={true}
                    styles={customstyles}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Employee Name<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="text"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Employee Name"
                  id="name"
                  name="name"
                  value={name}
                  onInput={(e) =>
                  (e.target.value = e.target.value.replace(
                    /[^a-zA-Z0-9\s]/g,
                    ""
                  ))
                  }
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const trimmedValue = enteredValue.trim();
                    if (trimmedValue !== "" || enteredValue === "") {
                      setName(enteredValue);
                    }
                  }}
                  required
                  autoComplete="off"
                  maxLength={50}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Email Id<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="email"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Employee Email Id"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Contact No<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="tel"
                  className="py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                  placeholder="Enter Employee Contact No"
                  id="contact"
                  name="contact"
                  value={contact}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                  }
                  onChange={(e) => setContact(e.target.value)}
                  required
                  minLength={0}
                  // maxLength={10}
                  autoComplete="off"
                />
              </div>
              {error && <p className="text-[#fc0000]">{error}</p>}
              <div className="flex justify-end">
                <button
                  onSubmit={handleSave}
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] w-[100px] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
//****************************************** Doctor Create Modal *********************************
export const DoctorCreateModal = ({ setDocModal }) => {
  const dispatch = useDispatch();
  // get token from local storage
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [contactno, setContactno] = useState();
  const [address, setAddress] = useState();
  const [uploadimages, setuploadimages] = useState([]);
  const [uploadimagesURLs, setuploadimagesURLs] = useState([]);
  const [qualification, setQualification] = useState();
  const [ImageValidationError, setImageValidationError] = useState("");
  let errorsObj = { uploadimage: "", image: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (uploadimages?.length < 1) return;
    const newImageURLs = [];
    uploadimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setuploadimagesURLs(newImageURLs);
  }, [uploadimages]);

  const onuploadimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    const file = e?.target?.files[0];
    setImageValidationError("");
    if (file) {
      const fileType = file.type;
      if (fileType === "image/gif") {
        errorObj.uploadimage =
          "Please upload an image with the .jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.webp extension";
        setErrors(errorObj);
        resetImageState();
        return;
      }
      if (file.size > 5000000) {
        errorObj.uploadimage = "The file should not be greater than 5 MB.";
        setErrors(errorObj);
        resetImageState();
        return;
      }
    }

    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        errorObj.uploadimage = "";
        setErrors(errorObj);
        setuploadimages([file]);
        setImageValidationError("");
      };
      image.onerror = () => {
        errorObj.uploadimage = "Invalid image file.";
        setErrors(errorObj);
        resetImageState();
        error = true;
      };
    }

    if (error) {
      return;
    }
  };
  const resetImageState = () => {
    setuploadimages([]);
    setuploadimagesURLs([]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");
    if (uploadimages.length === 0) {
      setImageValidationError("Please upload the profile image.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("profilepic", uploadimages[0]);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("contactno", contactno);
      formData.append("address", address);
      formData.append("qualification", qualification);
      dispatch(CreateDoctor(formData, token));
      dispatch(GetDoctorList());
      dispatch(DoctorLoader());
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-30 bg-opacity-50">
        <div className="bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className=" rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Doctor</div>
            <button
              onClick={() => {
                setDocModal(false);
                setName("");
                setQualification("");
                setEmail("");
                setContactno("");
                setAddress("");
                setImageValidationError("");
                setuploadimages([]);
                setuploadimagesURLs("");
                setErrors("");
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Doctor Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Doctor Name"
                    id="name"
                    name="name"
                    value={name}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^a-zA-Z0-9\s]/g,
                        ""
                      )
                      if (!e.target.value.trim()) {
                        e.target.value = "";
                      }
                    }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Qualification<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Doctor Qualification"
                    id="qualification"
                    name="qualification"
                    value={qualification}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^a-zA-Z0-9\s]/g,
                        ""
                      );
                      if (!e.target.value.trim()) {
                        e.target.value = "";
                      }
                    }}
                    onChange={(e) => {
                      setQualification(e.target.value);
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Email Id<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm w-[300px] outline-[#FCDD00]"
                    type="email"
                    placeholder="Enter Doctor Email Id"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Contact No<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="w-[300px] py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                    type="tel"
                    placeholder="Enter Doctor Contact No"
                    id="contact"
                    name="contact"
                    value={contactno}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                    }
                    onChange={(e) => setContactno(e.target.value)}
                    required
                    autoComplete="off"
                    minLength={0}
                  // maxLength={10}
                  />
                </div>
              </div>
              <div className="md:flex gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Address<span className="text-[#fc0000]">*</span> :
                  </span>
                  <textarea
                    className="py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] w-[300px] outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Doctor Address"
                    value={address}
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setAddress(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={100}
                    rows={4}
                  />
                </div>

                <div className="">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Profile Picture<span className="text-[#fc0000]">*</span> :
                  </span>
                  <label htmlFor="inputTagsf">
                    <div className="bg-[#fff] w-[100px] h-[100px] xl:h-[105px] xl:w-[105px] overflow-hidden border-dashed border-2 border-[#FCDD00] flex items-center justify-center cursor-pointer  rounded-[10px] mt-[6px]">
                      <input
                        id="inputTagsf"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        className="hidden"
                        onChange={onuploadimagechange}
                      />
                      {uploadimagesURLs && uploadimagesURLs[0] ? (
                        <div className="w-full h-full relative group p-1">
                          <img
                            src={uploadimagesURLs}
                            className="w-full h-full rounded-[6px]"
                            alt="new"
                          ></img>
                          <div className="">
                            <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                              <img src={upload} className="mx-auto" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex justify-center items-center h-full">
                          <div className=" text-center">
                            <img src={upload} className="mx-auto" />
                            <p className="text-[#FCDD00] text-sm font-medium font-poppins">
                              Upload Image
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </label>
                  {errors.uploadimage && (
                    <div className="mt-2 text-[#FF0101] text-sm font-poppins text-center">
                      {errors.uploadimage}
                    </div>
                  )}
                  {ImageValidationError && (
                    <p className="mt-2 text-[#FF0101] text-sm font-poppins text-center">
                      {ImageValidationError}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  onSubmit={handleSubmit}
                  type="submit"
                  className="w-[100px] bg-[#FCDD00] text-[#1A1A1A]  px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
//****************************************** Health Tips Create Modal *********************************
export const HealthTipsCreateModal = ({ setUploadImageModal }) => {
  const dispatch = useDispatch();
  // get token from local storage
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const [uploadimages, setuploadimages] = useState([]);
  const [uploadimagesURLs, setuploadimagesURLs] = useState([]);
  const [ImageValidationError, setImageValidationError] = useState("");

  let errorsObj = { uploadimage: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (uploadimages?.length < 1) return;
    const newImageURLs = [];
    uploadimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setuploadimagesURLs(newImageURLs);
  }, [uploadimages]);

  const onuploadimagechange = (e) => {
    const errorObj = { ...errorsObj };
    const file = e?.target?.files[0];

    if (file) {
      const fileType = file.type;
      const allowedExtensions = [
        "image/jpeg",
        "image/jpg",
        "image/jfif",
        "image/pjpeg",
        "image/pjp",
        "image/png",
        "image/webp",
      ];

      if (!allowedExtensions.includes(fileType)) {
        errorObj.uploadimage =
          "The image file is invalid.Please upload an image with the .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp extension";
        setErrors(errorObj);
        setImageValidationError("");
        return;
      } else {
        setErrors(errorObj);
      }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        setuploadimages([file]);
        setImageValidationError("");
      };

      image.onerror = () => {
        errorObj.uploadimage =
          "The image file is invalid. Please upload an image with the .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp extension.";
        setErrors(errorObj);
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");
    if (uploadimages.length === 0) {
      setImageValidationError(
        "Please upload an image with the .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp extension."
      );
      return;
    } else {
      setImageValidationError("");
    }
    try {
      const formData = new FormData();
      formData.append("image", uploadimages[0]);
      dispatch(uploadImage(formData, token));
      dispatch(GetImageList());
      dispatch(imageLoader());
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-30 bg-opacity-50">
        <div className="w-[340px] md:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Health Tips Image</div>
            <button
              onClick={() => {
                setUploadImageModal(false);
                setuploadimages([]);
                setuploadimagesURLs([]);
                setErrors("");
                setImageValidationError("");
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-3 xl:p-5 flex flex-col gap-5">
              <div className="flex flex-col gap-1">
                <div className=" flex justify-center">
                  <label htmlFor="inputTagsf">
                    <div className="bg-[#fff] h-[200px]  xl:h-[250px] w-[310px] md:w-[385px] overflow-hidden border-dashed border-2 border-[#FCDD00] flex items-center justify-center cursor-pointer  rounded-[10px]">
                      <input
                        id="inputTagsf"
                        type="file"
                        accept=" image/jpeg, image/jpg, image/jfif, image/pjpeg, image/pjp, image/webp"
                        // accept="image/*"
                        className="hidden"
                        // multiple

                        onChange={onuploadimagechange}
                      />
                      {uploadimagesURLs && uploadimagesURLs[0] ? (
                        <div className=" col-span-6 w-full h-full p-1">
                          <img
                            src={uploadimagesURLs[0]}
                            className="w-full h-full rounded-[6px]"
                            alt="new"
                          ></img>
                          <div className="">
                            <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                              <svg
                                className="w-8 h-8 mx-auto text-[#FCDD00]"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex justify-center items-center h-full">
                          <div className=" text-center">
                            <svg
                              className="w-10 h-10 text-[#FCDD00] mx-auto"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p className="text-[#FCDD00] text-[24px] font-medium font-poppins mt-2">
                              Upload Image
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                {errors.uploadimage && (
                  <div className=" text-[#FF0101] text-sm font-poppins text-center">
                    {errors.uploadimage}
                  </div>
                )}
                {ImageValidationError && (
                  <p className=" text-[#FF0101] text-sm font-poppins text-center">
                    {ImageValidationError}
                  </p>
                )}
              </div>

              <div className="flex justify-end">
                <button
                  onSubmit={handleSubmit}
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] px-5 py-3 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer w-full "
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
//****************************************** Lab Test Create Modal *********************************
export const LabTestCreateModal = ({ setLabModal }) => {
  const dispatch = useDispatch();
  // get token from local storage
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [uploadimages, setuploadimages] = useState([]);
  const [uploadimagesURLs, setuploadimagesURLs] = useState([]);
  const [imageValidationError, setImageValidationError] = useState("");
  let errorsObj = { uploadimage: "", image: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (uploadimages?.length < 1) return;
    const newImageURLs = [];
    uploadimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setuploadimagesURLs(newImageURLs);
  }, [uploadimages]);

  const onuploadimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    const file = e?.target?.files[0];
    setImageValidationError("");
    if (file) {
      const fileType = file.type;
      if (fileType === "image/gif") {
        errorObj.uploadimage =
          "Please upload an image with the .jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.webp extension";
        setErrors(errorObj);
        resetImageState();
        return;
      }
      if (file.size > 5000000) {
        errorObj.uploadimage = "The file should not be greater than 5 MB.";
        setErrors(errorObj);
        resetImageState();
        return;
      }
    }

    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        errorObj.uploadimage = "";
        setErrors(errorObj);
        setuploadimages([file]);
        setImageValidationError("");
      };

      image.onerror = () => {
        errorObj.uploadimage = "Invalid image file.";
        setErrors(errorObj);
        resetImageState();
        error = true;
      };
    }
    if (error) {
      return;
    }
  };
  const resetImageState = () => {
    setuploadimages([]);
    setuploadimagesURLs([]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");
    if (uploadimages.length === 0) {
      setImageValidationError("Please upload the logo.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("image", uploadimages[0]);
      formData.append("name", name);
      formData.append("url", url);
      dispatch(uploadLabTest(formData, token));
      dispatch(GetLabList());
      dispatch(labLoader());
    } catch (error) { }
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
        <div className="w-[340px] sm:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Lab</div>
            <button
              onClick={() => {
                setLabModal(false);
                setName("");
                setUrl("");
                setErrors("");
                setImageValidationError("");
                setuploadimages([]);
                setuploadimagesURLs([]);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-3 xl:p-5 flex flex-col gap-5">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Lab Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Lab Name"
                    id="name"
                    name="name"
                    value={name}
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    ))
                    }
                    onChange={(e) => {
                      const enteredValue = e.target.value;
                      const trimmedValue = enteredValue.trim();
                      if (trimmedValue !== "" || enteredValue === "") {
                        setName(enteredValue);
                      }
                    }}
                    required
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    URL<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="url"
                    placeholder="Enter Redirect URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    required
                    autoComplete="off"
                  // maxLength={50}
                  />
                </div>

                <div className="">
                  <span className="font-Poppins text-[15px] xl:text-base">
                    Add Logo<span className="text-[#fc0000]">*</span> :
                  </span>
                  <div className="w-[110px] h-[110px]">
                    <label htmlFor="inputTagsf">
                      <div className="bg-[#fff] w-[100px] h-[100px] xl:h-[105px] xl:w-[105px] overflow-hidden border-dashed border-2 border-[#FCDD00] flex items-center justify-center cursor-pointer  rounded-[10px] mt-[6px]">
                        <input
                          id="inputTagsf"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          className="hidden"
                          onChange={onuploadimagechange}
                        />
                        {uploadimagesURLs && uploadimagesURLs[0] ? (
                          <div className="w-full h-full relative group p-1">
                            <img
                              src={uploadimagesURLs}
                              className="w-full h-full rounded-[6px]"
                              alt="new"
                            ></img>
                            <div className="">
                              <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                                <img src={upload} className="mx-auto" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=" flex justify-center items-center h-full">
                            <div className=" text-center">
                              <img src={upload} className="mx-auto" />
                              <p className="text-[#FCDD00] text-sm font-medium font-poppins">
                                Upload Image
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  {errors.uploadimage && (
                    <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                      {errors.uploadimage}
                    </div>
                  )}
                  {imageValidationError && (
                    <p className="mt-2 text-[#FF0101] text-sm font-poppins">
                      {imageValidationError}
                    </p>
                  )}
                </div>

              </div>
              <div className="flex justify-end">
                <button
                  onSubmit={handleSubmit}
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer w-[100px] outline-none"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
////**************************************** Password Modal ***************************************
export const LoginDetailModal = ({ setLoginDetailModal }) => {

  const logindetail = useSelector((state) => state?.corporateData?.CorpoLoginDetails)
  // console.log(logindetail, "logindetail");

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
        <div className="w-[340px] sm:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Login Detail</div>
            <button
              onClick={() => {
                setLoginDetailModal(false)
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <div className="p-3 xl:p-5 flex flex-col gap-5">
            <div className="flex flex-col gap-3">

              {logindetail?.data && (
                <>
                  <table align="center">
                  <tr>
                      <td className="font-Poppins text-black text-base pl-[20px]">
                        <span className="font-medium">Email</span>
                      </td>
                      <td>: {logindetail?.data?.email}</td>
                    </tr>
                    <tr>
                      <td className="font-Poppins text-black text-base pl-[20px]">
                        <span className="font-medium">Contact No.</span>
                      </td>
                      <td>: {logindetail?.data?.contactno}</td>
                    </tr>
                    <tr>
                      <td className="font-Poppins text-black text-base pl-[20px]">
                        <span className="font-medium">Password</span>
                      </td>
                      <td>: {logindetail?.data?.password}</td>
                    </tr>
                  </table>
                </>
              )}
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => setLoginDetailModal(false)}
                type="submit"
                className="bg-[#FCDD00] text-[#1A1A1A] px-5 py-1 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer w-[100px] outline-none"
              >
                ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
import axios from "axios";
import Swal from "sweetalert2";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Swal.fire({
        title: "Authentication Error",
        text: "Your session has expired. Please log in again.",
        icon: "error",
        confirmButtonText: "Login",
        confirmButtonColor: "#FCDD00",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("Admin");
          window.location.href = "/login";
        }
      });
    }
    return Promise.reject(error);
  }
);

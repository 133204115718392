// import { useEffect } from "react";

// const UseOutsideClick = (ref, callback) => {
//   const handleClick = (e) => {
//     if (ref.current && !ref.current.contains(e.target)) {
//       callback();
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClick);

//     return () => {
//       document.removeEventListener("mousedown", handleClick);
//     };
//   });
// };

// export default UseOutsideClick;

import { useEffect } from "react";

const UseOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
};

export default UseOutsideClick;





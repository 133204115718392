import React, { useEffect } from "react";
import DoctorLayout from "../../component/layout/DoctorLayout/DoctorLayout";
import { useDispatch, useSelector } from "react-redux";
import { GetTimeSlot } from "../../Redux/Action/DoctorAction";

const TimeSlote = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetTimeSlot());
  }, []);

  const timeslot = useSelector(
    (state) => state?.DoctorData?.doctimeslote?.data
  );
  // console.log(timeslot);
  return (
    <>
      <DoctorLayout>
        <div className="p-5">
          coming soon....
          {/* <div>
            <div>
              <p className="sm:text-[22px] text-[#1A1A1A] font-poppins font-medium capitalize">
                Choose Your Slot
              </p>
            </div>
            <div className="mt-3">
              <div className=" flex gap-3 text-[#1A1A1A] font-poppins font-medium capitalize">
                {timeslot?.dates?.map((user, index) => (
                  <div key={index}>
                    <p className="text-center">{user.day}</p>
                    <div className="border-[#FCDD00] p-1 border flex justify-center items-center bg-white rounded-lg">
                    {user.date}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          for morning time 
          <div className="mt-5">
            <div>
              <p className="sm:text-[22px] text-[#1A1A1A] font-poppins font-medium capitalize">
                Morning
              </p>
            </div>
            <div className="mt-3">
              <div className=" text-[#1A1A1A] font-poppins font-medium capitalize w-[100px]">
              {timeslot?.timeSlots?.map((user, index) => (
                  <div key={index}>
                    <div className="border-[#FCDD00] p-1 border flex justify-center items-center bg-white rounded-lg">
                 {user?.morning[2]}
                </div>
                  </div>
                ))}
                <div className="border-[#FCDD00] p-1 border flex justify-center items-center bg-white rounded-lg">
                  09:00 AM
                </div>
              </div>
            </div>
          </div>
          for Afternoon time 
          <div className="mt-5">
            <div>
              <p className="sm:text-[22px] text-[#1A1A1A] font-poppins font-medium capitalize">
                Afternoon
              </p>
            </div>
            <div className="mt-3">
              <div className=" text-[#1A1A1A] font-poppins font-medium capitalize w-[100px]">
                <div className="border-[#FCDD00] p-1 border flex justify-center items-center bg-white rounded-lg">
                  02:00 PM
                </div>
              </div>
            </div>
          </div>
          for Evening time 
          <div className="mt-5">
            <div>
              <p className="sm:text-[22px] text-[#1A1A1A] font-poppins font-medium capitalize">
                Evening
              </p>
            </div>
            <div className="mt-3">
              <div className=" text-[#1A1A1A] font-poppins font-medium capitalize w-[100px]">
                <div className="border-[#FCDD00] p-1 border flex justify-center items-center bg-white rounded-lg">
                  06:00 PM
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </DoctorLayout>
    </>
  );
};

export default TimeSlote;

import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import UseOutsideClick from "../Admin/UseOutsideClick";
//import modal
import { ResetPassModal } from "../Modal/HrModal/ResetPasswordModal";
//import icone
import logo1 from "../../images/Navbar/logo1.png";
import logo2 from "../../images/Navbar/logo2.png";
import menu from "../../images/Navbar/menu.svg";
import { RiLockPasswordLine } from "react-icons/ri";

export const AdminNavigation = ({ setsidemenu }) => {
  const navigate = useNavigate();
  const [showtoggel, setShowtoggel] = useState(false);
  const [resetPasswordModel, setResetPasswordModel] = useState(false);

  const logout = () => {
    localStorage.removeItem("Admin");
    navigate("/login");
  };
  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (showtoggel) {
      setShowtoggel(false);
    }
  });

  //to get User Detail from local storage
  const user = localStorage.getItem("Admin");
  const userData = JSON.parse(user);
  const userName = userData?.data?.name;
  const userRole = userData?.data?.role;
  const userPic = userData?.data?.profilepic;
  let firstLetter = "";
  if (userName) {
    firstLetter = userName.charAt(0);
  }

  const [error, seterror] = useState("");

  const newData = useSelector((state) => state?.Auth?.resetpassword);
  useEffect(() => {
    if (newData.code === 200) {
      setResetPasswordModel(false);
    }
  }, [newData]);

  useEffect(() => {
    if (resetPasswordModel) {
      setShowtoggel(false);
    } else {
      setShowtoggel(false);
    }
  }, [resetPasswordModel]);

  const handelClose = () => {
    setShowtoggel(false);
    setResetPasswordModel(false);
    seterror("");
  };

  return (
    <>
      <div className="  bg-white sticky top-0 z-10">
        <div className="flex justify-between items-center px-5 md:px-14 py-2">
          <div className="ml-[20px] md:ml-[6px] lg:hidden">
            <img src={menu} alt="new" onClick={() => setsidemenu(true)}></img>
          </div>
          <div className="flex justify-center items-center gap-3">
            <img className="w-10 h-10" src={logo1} alt="logo" />
            <img className="" src={logo2} alt="logo" />
          </div>
          <div
            className="flex flex-col gap-1 items-center relative cursor-pointer"
            onClick={() => setShowtoggel(!showtoggel)}
          >
            {userRole === "Doctor" ? (
              <div className="w-[34px] h-[34px] rounded-full bg-[#FCDD00] flex justify-center items-center font-bold capitalize overflow-hidden">
                {userPic?.length === 0 ? (
                  <div>{firstLetter}</div>
                ) : (
                  <img
                    src={`${userData?.storageurl}${userPic}`}
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
            ) : userRole === "Corporate" ? (
              <div className="w-[34px] h-[34px] rounded-full bg-[#00ADEF] flex justify-center items-center font-bold capitalize overflow-hidden">
                {userPic?.length === 0 ? (
                  <div>{firstLetter}</div>
                ) : (
                  <img
                    src={`${userData?.storageurl}${userPic}`}
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
            ) : (
              <div className="w-[34px] h-[34px] rounded-full bg-[#FCDD00] flex justify-center items-center font-bold capitalize">
                {firstLetter}
              </div>
            )}

            <div>
              {userName?.length > 12 ? (
                <div className="tooltip z-30">
                  <p className="font-Poppins font-normal text-sm capitalize">{`${userName?.substring(
                    0,
                    12
                  )}...`}</p>
                  <span className="tooltiptext z-40">{userName}</span>
                </div>
              ) : (
                <p className="font-Poppins font-normal text-sm capitalize">
                  {userName}
                </p>
              )}
            </div>

            {showtoggel && (
              <div
                className="absolute top-[77px] right-[-6px] md:right-[-36px]"
                style={{ zIndex: 60 }}
                ref={ref}
              >
                <div className="relative bg-white shadow-xl w-56 rounded-[10px]">
                  <ul className="py-3 flex flex-col font-Roboto text-base">
                    {["Hr", "Corporate", "Doctor"].includes(userRole) && (
                      <li
                        onClick={() => {
                          setResetPasswordModel(true);
                          setShowtoggel(false);
                        }}
                        className="py-2 px-5 hover:cursor-pointer flex items-center gap-2 hover:bg-[#969ba0] hover:bg-opacity-10 text-[#969ba0] hover:text-[#FCDD00]"
                      >
                        <RiLockPasswordLine className="w-6 h-6 text-[#FCDD00]" />
                        <span className="text-base  font-normal">
                          Change Password
                        </span>
                      </li>
                    )}
                    <li
                      onClick={logout}
                      className="py-2 px-5 hover:cursor-pointer flex items-center gap-2 hover:bg-[#969ba0] hover:bg-opacity-10 text-[#969ba0] hover:text-[#FCDD00]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-[#FCDD00]"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      <button className="font-Roboto text-base font-normal">
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {/* ----------------------------------reset password model-------------------------  */}
            {resetPasswordModel && (
              <div>
                <ResetPassModal {...{ handelClose }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
